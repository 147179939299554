import React, { Component } from "react";
import styled, { css } from "styled-components";
import {themes} from "../constants/Colors"
import moment from 'moment';
import 'moment/locale/ru';

class Print extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '123456789',
    }
  }

  render(){
    let baseTree = {}
    this.props.base.productBase.map((item, index) => {
      baseTree[item.idp] = item
    })
    let baseFridges = {}
    this.props.base.fridgeBase.map((itemf, indexf) => {
      baseFridges[itemf.idf] = itemf
    })
    return (
      <PrintForm
        onClick={() => { this.props.route('NewGoods') }}
      >
        {this.props.sourceToPrint.map((item, index)=>{
          let rusType = ''
          switch(baseTree[item.idp].type){
            case 'soup':
              rusType = 'суп'
              break
            case 'entree':
              rusType = 'горячее'
              break
            case 'salad':
              rusType = 'салат'
              break
            case 'dessert':
              rusType = 'десерт'
              break
            case 'breakfast':
              rusType = 'завтрак'
              break
            case 'drink':
              rusType = 'напиток'
              break
            case 'bakery':
              rusType = 'выпечка'
              break
          }
          return(
              <div
              key={index}
                  
              style={{ pageBreakAfter: "always", width: 116,}}
                >
                
                <img src={"http://qrcoder.ru/code/?" + item.qr +"&6&0"} width="116" height="116" border="0" title="QR код"/>
                
                <p
                style={{
                  width: 116,
                  fontSize: 10,
                  margin: 1}}
                >{baseTree[item.idp].name}</p>
                <p
                style={{
                  width: 116,
                  fontSize: 12,
                  margin: 1}}
                >{rusType}</p>
                <p
                  style={{
                    width: 116,
                    fontSize: 8,
                    margin: 1
                  }}
                >{item.idSource+" | "+baseTree[item.idp].val}</p>
                <p
                style={{
                  width: 116,
                  fontSize: 9,
                  margin: 0
                }}>годен до: </p>
              <p
                style={{
                  width: 116,
                  fontSize: 10,
                  margin: 0
                }}>{moment(+item.timeLost * 1000).format('lll')}</p>
                <p
                style={{
                  width: 116,
                  fontSize: 14,
                  margin: 1
                }}>цена: {item.price} руб.</p>
                <p
                style={{
                  width: 116,
                  fontSize: 8,
                  margin: 0
                }}>{baseFridges[item.idf].addres}</p>
              </div>        
            )}
        )}
      </PrintForm>
    );
  }
}

const PrintForm = styled.div`
 
  float:none;
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  cursor: alias;
  
  ${'' /* flex-direction: column;
  justify-content: flex-start;
  align-item: center; */}
`;

export default Print;