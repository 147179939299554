import React, { Component, useState } from "react";
import styled, { css } from "styled-components";
import axi from '../functions/axiosf';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Context} from "../functions/context"

function KitSelect(props) {
const classes = useStyles();
    return (
      <Context.Consumer>
      {({base}) => (
      <div
        className='top'
        style={{ 
          position: "fixed",
          backgroundColor: "#efefefd6",
          top: 80,
          zIndex: 5,
          }}
      >
        <FormControl
          variant='outlined' 
             className={classes.margin}>
          <InputLabel id="demo-simple-select-outlined-label">Набор</InputLabel>
          <Select
            label="Набор"
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={props.selectedKIt}
            onChange={
              (e)=>{
                props.onChangeKit(e.target.value)
                }
              }
          >
            {props.quickAccessKitGroups.map((item, index)=>{
              return(
                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
      )}
      </Context.Consumer>
    );
  
}

export default KitSelect;

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));