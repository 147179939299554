import React, { Component } from "react";
import styled, { css } from "styled-components";

function GoogFullStatus(props) {
  let sold = 0
  let overdue = 0
  let soon = 0
  let fresh = 0

  props.sourcesTree.map((item, index) => {
    if (item.stage =='eaten'){
      sold++
    }
    else
    {
      if (+item.timeLost*1000 < +new Date()){
        overdue++
      }
      else
      {
        if ((+item.timeLost * 1000 - +new Date())<(3600*1000*6)) {
          soon++
        }
        else
        {
          fresh++
        }
      }
    }
  })
  if (props.selectedTable){
  return (    
    <Container {...props}
    style={{
      flexDirection: 'row',
      ...props.style
    }}>
      <Group4>
        <GoodsName
          style={{width: 170}}
        >{(props.productBase && props.idp) && props.productBase[props.idp].name}</GoodsName>
      </Group4>
        <div
          style={{
            display: 'flex',
            height: 40,
            backgroundColor: "rgba(247,245,193,1)",
            justifyContent: "center",
            alignItems: "center",
            width:40
          }}
        >{sold}</div>
      
        <div
        style={{
            display: 'flex',
            height: 40,
            backgroundColor: "rgba(247,193,193,1)",
            justifyContent: "center",
            alignItems: "center",
            width:40
          }}
          >{overdue}</div>
     
        <div
        style={{
            display: 'flex',
            height: 40,
            backgroundColor: "rgba(247,245,193,1)",
            justifyContent: "center",
            alignItems: "center",
            width:40
          }}
          >{soon}</div>
     
        <LoremIpsum>{fresh}</LoremIpsum>
      
    </Container>)
  }else{
  return (
    <Container {...props}>
      <Group4>
        <GoodsName>{(props.productBase && props.idp) && props.productBase[props.idp].name}</GoodsName>
      </Group4>
      <Group3>
        <Продано>Продано</Продано>
        <LoremIpsum3>{sold}</LoremIpsum3>
      </Group3>
      <Group2>
        <Просрочено>Просрочено</Просрочено>
        <LoremIpsum2>{overdue}</LoremIpsum2>
      </Group2>
      <Group3>
        <Продано>Скоро</Продано>
        <LoremIpsum3>{soon}</LoremIpsum3>
      </Group3>
      <Group>
        <Свежее>Свежее</Свежее>
        <LoremIpsum>{fresh}</LoremIpsum>
      </Group>
    </Container>
  );}
}

const Container = styled.div`
  display: flex;
  border-radius: 8px;
  margin-top: 8px;
  margin: 4px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(206,236,178,1);
  box-shadow: 2px 2px 8px 1px #0a190375;
`;

const Group4 = styled.div`
  flex-direction: column;
  width: 140px;
  justify-content: center;
  flex: 1 1 0%;
  display: flex;
`;

const GoodsName = styled.span`
  font-family: Roboto-700;
  color: #121212;
  width: 140px;
  font-size: 14px;
  height: 34px;
  text-align: center;
`;

const Group3 = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 0%;
  background-color: rgba(247,245,193,1);
  width: 160px;
  display: flex;
`;

const Продано = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 16px;
  width: 99px;
  margin-left: 10px;
`;

const LoremIpsum3 = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 14px;
  width: 35px;
  text-align: right;
  margin-right: 10px;
`;

const Group2 = styled.div`
  width: 160px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 0%;
  background-color: rgba(247,193,193,1);
  display: flex;
`;

const Просрочено = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 17px;
  width: 99px;
  margin-left: 10px;
`;

const LoremIpsum2 = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 14px;
  width: 35px;
  text-align: right;
  margin-right: 10px;
`;

const Group = styled.div`
  width: 160px;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1 0%;
  align-items: center;
  display: flex;
`;

const Свежее = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 13px;
  width: 99px;
  margin-left: 10px;
`;

const LoremIpsum = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 14px;
  width: 35px;
  text-align: right;
  margin-right: 10px;
`;

export default GoogFullStatus;
