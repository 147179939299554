import React, { Component } from "react";
import styled, { css, ThemeConsumer } from "styled-components";
import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";

import axi from "../functions/axiosf"

import moment from 'moment';
class QuickSetStack extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '123456789',
      idSelected: '',
      num: '',
      price: '',
      exp: '48',
      isSaved: true,
    }
  }

  componentDidMount(){
    this.setState({
      idSelected: this.props.item.idp,
      num: this.props.item.num,
      price: this.props.item.price,
      exp: this.props.item.exp,
    })
  }

  //удаление позиции
  //обновление позиции

  updKit = (mode) => {
    let params = {
      ...this.state,
      idf: +this.props.idf,
      kitGroup:this.props.selectedKIt,
    }
    axi("createQRs.php", mode, params).then((result) => {
      if (result.type == 'approved') {
        this.setState({ isSaved: true})
        this.props.update(this.props.parent)
      } else {
        console.log(result)
      }
    }, (e) => { console.log(e) })

  }
  render(){
    return (
      <Container 
        style={{
          flexDirection: this.props.selectedTable?'row':'column',
          alignItems: this.props.selectedTable?'center': null,
          ...this.props.style
        }}
        
      >
      <LoremIpsum> {this.props.name} </LoremIpsum>
        <RowDiv
        style={{
          width: this.props.selectedTable? 80 : 160,
        }}
        >
          <TextInput2 placeholder="кол-во"
                onChange={(e) => { this.setState({ num: e.target.value, isSaved: false }) }}
                value={this.state.num}
          />
          <p>{' шт. '}</p>
        </RowDiv>
        <RowDiv
        style={{
          width: this.props.selectedTable? 80 : 160,
        }}
        >
          <TextInput2 placeholder="цена"
                onChange={(e) => { this.setState({ price: e.target.value, isSaved: false }) }}
                value={this.state.price}
          />
          <p>{' руб. '}</p>
        </RowDiv>
        <RowDiv
        style={{
          width: this.props.selectedTable? 80 : 160,
        }}
        >
          <TextInput2 placeholder="срок"
                onChange={(e) => { this.setState({ exp: e.target.value, isSaved: false }) }}
                value={this.state.exp}
          />
          <p>{' ч. '}</p>
        </RowDiv>
        <RowDiv
          style={{
            width: this.props.selectedTable? 80 : 160,
            justifyContent: 'flex-end'
          }}
          >
          {!this.state.isSaved&&
            <IoniconsIcon
              name="ios-done-all"
            onClick={()=>{this.updKit('UpdateQuickAccessKit')}}
              style={{
                color: "rgba(45,89,141,1)",
                fontSize: 30,
                margin: 4,
                marginRight: this.props.selectedTable?4:16,
                  cursor: 'pointer'
              }}
            />
          }
          <IoniconsIcon
            name="ios-trash"
            onClick={()=>{this.updKit('DeleteQuickAccessKit')}}
            style={{
              color: "rgba(45,89,141,1)",
              fontSize: 30,
              margin: 4,
              marginRight: this.props.selectedTable?4:16,
                  cursor: 'pointer'
            }}
          />
        </RowDiv>  
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  background-color: rgb(220 218 218);
  border-radius: 8px;
  flex-direction: column;
  box-shadow: 2px 2px 8px 1px #0a190375;
  overflow: hidden;
  justify-content: space-between;
`;

const RowDiv = styled.div`
display: flex;
  flex-direction: row;
`

const TextInput2 = styled.input`
  font-family: Roboto-300;
  color: #121212;
  height: 30px;
  width: 37px;
  background-color: #6e95c1a8;
  border-radius: 8px;
  text-align: center;
  margin-left: 8px;
  margin-right: 4px;
  border: none;
  box-shadow: inset 2px 2px 4px 1px #0a190375;
`;
const LoremIpsum = styled.span`
  font-family: Roboto-700;
  
  color: #121212;
  height: 14px;
  margin-top: 12px;
  margin-left: 9px;
`;
export default QuickSetStack;