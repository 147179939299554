import React, { Component } from "react";
import styled, { css } from "styled-components";
import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";
import axi from "../functions/axiosf"

class EditFridge extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      idf: '',
      locking: '',
      qr: '',
      addres: '',
      location_x: '',
      location_y: '',
      ip: '',
    }
  }

  componentDidMount(){
    this.setState(this.props.item)
  }

  edit = () => {
    let params = {
      ...this.state,
      token: this.props.token,
    }
    axi("EditFridge.php", null, params).then((result) => {
      if (result.type == 'approved') {
        alert('холодильник по адресу ' + this.state.addres+' успешно отредактирован')
        this.props.update('Fridges')
      } else {
        //this.setState({ screen: 'LogIn' })
        console.log(result)
      }
    }, (e) => { console.log(e) })

  }

  render(){
  return (
    <Container 
      style={{
        ...this.props.style,
        backgroundColor: +this.state.locking ? "#B9BCEF" : "#B1EFD1",
        boxShadow: +this.state.locking ? "2px 2px 8px 4px #9597B3" : "2px 2px 8px 4px #90B3A2",
        }}     
      >
      <p>Адрес холодильника</p>
      <TextInput 
        placeholder="Большая Пушкарская 29"
        onChange={(e) => { this.setState({ addres: e.target.value}) }}
        value={this.state.addres}
        style={{
          backgroundColor: +this.state.locking ? "#D9DAF7" : "#D4F7E6"
          }}/>
      <p>Долгота</p>
      <TextInput 
        placeholder="30.297063"
        onChange={(e) => { this.setState({ location_y: e.target.value }) }}
        value={this.state.location_y}
        style={{
          backgroundColor: +this.state.locking ? "#D9DAF7" : "#D4F7E6"
        }}
      />
      <p>Широта</p>
      <TextInput
        placeholder="60.056123"
        onChange={(e) => { this.setState({ location_x: e.target.value }) }}
        value={this.state.location_x}
        style={{
          backgroundColor: +this.state.locking ? "#D9DAF7" : "#D4F7E6"
        }}
      />
      <p>Внешний IP адрес</p>
      <TextInput
        placeholder="60.160.0.1"
        onChange={(e) => { this.setState({ ip: e.target.value }) }}
        value={this.state.ip}
        style={{
          backgroundColor: +this.state.locking ? "#D9DAF7" : "#D4F7E6"
        }}
      />
      {this.state.addres!=""&&   
        this.state.location_x != "" &&
        this.state.location_y != "" &&
        this.state.ip != "" &&   
        <div>
          <IoniconsIcon
            name="ios-create"
            style={{
              color: "#3C419B",
              fontSize: 50,
              margin: 16,
            }}
            onPress={() => this.edit()}
          ></IoniconsIcon>
          <a 
            target="_blank" 
            href={"http://qrcoder.ru/code/?"+this.state.qr+"&10&0"}>
            <IoniconsIcon
              name="md-barcode"
              style={{
                color: "#3C419B",
                fontSize: 50,
                margin: 16,
              }}
              //onPress={() => this.create()}
            ></IoniconsIcon>
          </a>
        </div>
      }
    </Container>
  );}
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #b2d5ec;
  box-shadow: 2px 2px 8px 4px #96b3c8;
  border-radius: 20px;
`;

const TextInput = styled.input`
  font-family: Roboto-300;
  color: #121212;
  height: 35px;
  width: 265px;
  border: none;
  margin-left: 8px; 
  fontSize: 26;
  background: transparent;
  background-color: #D9DAF7;
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 2px 2px 4px 1px #96b3c8;
  padding-left: 16px;
  margin-bottom: 4px;

`;

export default EditFridge;
