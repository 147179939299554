import React, { Component } from "react";
import styled, { css } from "styled-components";
import SingleStatusBlock from "../components/SingleStatusBlock";
import GoogFullStatus from "../components/GoogFullStatus";
import Top from "../components/Top"
import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";
import TopTable from "../components/TopTable"
import DateTimePicker from 'react-datetime-picker';

class Statistic  extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        token: '',
        dateFrom: 0,
        dateTo: 0,
      }
    }

    componentDidMount(){
    let t = +new Date()-60*60*24*7*1000
    this.setState({dateFrom: new Date(t), dateTo: new Date() })
  }
    
    handleDateChangeFrom = (d) => {
      this.setState({dateFrom: d})
      }

    handleDateChangeTo = (d) => {
      this.setState({dateTo: d})
      }

    render(){

      let ids = []
      let sourcesTree = {}

      this.props.base.sources.map((item, index) => {
        if (item.timeCreate>(+new Date(this.state.dateFrom)/1000)&&item.timeCreate<=(+new Date(this.state.dateTo)/1000)){
          if (item.idf === this.props.selectedFridge.idf){
            if (ids.indexOf(item.idp) == -1) {
              ids = [...ids, item.idp]
              sourcesTree[item.idp] = []
            }
            sourcesTree[item.idp] = [...sourcesTree[item.idp], item]
          }
        }
      })

      let baseTree = {}
      this.props.base.productBase.map((item, index) => {
        baseTree[item.idp] = item
      })


      console.log(sourcesTree)

      

  return (
    <Rect>
      <Top
        fridges={this.props.base.fridgeBase}
        onChangeFridge={(f)=>{this.props.onChangeFridge(f)}}
        selectedFridge={this.props.selectedFridge}
      />
{(this.props.selectedFridge.idf!='')&&
     <List>
      <div
        className='top'
        style={{
          flexDirection: this.props.selectedTable?'column':'row'
        }}
      />
      {!this.props.selectedTable?
      <SingleStatusBlock
        style={{
          height: 160,
          width: 160,
          marginTop: 8
        }}
      ></SingleStatusBlock>
      :<div
      style={{height: 60, width: '100%'}}></div>}
      {!this.props.selectedTable?
      <SingleStatusBlock
        style={{
          width: 160,
          height: 160,
          marginTop: 8
        }}
      ></SingleStatusBlock>
      :
      <TopTable
        column1={'название'}
        column2={'про- дано'}
        column3={'просро- чено'}
        column4={'скоро'}
        column5={'све- жее'}
      />
  }
        {ids.map((item, index) => {
          return (
            <GoogFullStatus
              idp={item}
              key={index}
              sourcesTree={sourcesTree[item]}
              productBase={baseTree}
              style={!this.props.selectedTable?
              {
                width: 160,
                height: 160,
                boxShadow: '2px 2px 8px 1px #0a190375',
              }:
              {
                width: 340,
                height: 40,
                boxShadow: '1px 1px 1px 1px #607d8b70',
                margin: 1,
                borderRadius: 0,
              }}
              
            selectedTable={this.props.selectedTable}
            />
          )
        })}
       <div
        className='top'
      /><div
        className='top'
      /><div
        className='top'
      />
      </List>
      }
        <Calendar>
          <Group2>
            <Date1Txt>Начало периода</Date1Txt>
            <DateTimePicker 
                onChange={(e) => { this.handleDateChangeFrom(e) }}
                value={this.state.dateFrom}
                locale={"ru-RU"}
                className='DateTimePicker'
                calendarIcon={ 
                  <IoniconsIcon
                    name="md-calendar"
                    style={{
                      color: "rgba(65,117,5,1)",
                      fontSize: 30,
                    }}
                  />}
            />
          </Group2>
          <Group2>
            <Date1Txt>Конец периода</Date1Txt>
              <DateTimePicker 
                onChange={(e) => { this.handleDateChangeTo(e) }}
                value={this.state.dateTo}
                locale={"ru-RU"}
                className='DateTimePicker'
                calendarIcon={<IoniconsIcon
                  name="md-calendar"
                  style={{
                    color: "rgba(117,5,5,1)",
                    fontSize: 30
                  }}
                />}
              />

          </Group2>
        </Calendar>
    </Rect>
  );}
}

const Rect = styled.div`
  display: flex;
  background-color: #E6E6E6;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  height: 100vh;
  width: 100vw;
`;

const List = styled.div`
  display: flex;
  background-color: #999;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  height: calc (100vh - 80px);
  width: 100vw;
  overflow-y: scroll;
`;



const Calendar = styled.div`
  background-color: #efefefd6;
  height: 120px;
  width: 320px;
  position: fixed;
  bottom: 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;    
  display: flex;
  flex-direction: column;
  justify-content: space-around;

`;

const Group2 = styled.div`
  width: 300px;
  height: 44px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

const Date1Txt = styled.span`
  font-family: Roboto-300;
  color: #121212;
  font-size: 16px;
  width: 90px;
  text-align: right;
  margin-right: 8px;
`;

const GoodsName = styled.span`
  font-family: Roboto-700;
  color: #121212;
  width: 140px;
  font-size: 14px;
  height: 34px;
  text-align: center;
`;


const LoremIpsum = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 14px;
  width: 35px;
  text-align: right;
  margin-right: 10px;
`;

export default Statistic;
