import React, { Component } from "react";
import styled, { css, ThemeProvider } from "styled-components";
import SingleStatusBlock from "../components/SingleStatusBlock";
import CustomerLogInfo from "../components/CustomerLogInfo";
import Top from "../components/Top"
import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";
import TopTableLog from "../components/TopTableLog"
import DateTimePicker from 'react-datetime-picker';
import {ScatterChart, AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, Label} from 'recharts'
import {lay} from '../constants/Layout'
import moment from 'moment';
import 'moment/locale/ru';

class PingLog  extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        token: '',
        dateFrom: 0,
        dateTo: 0,
      }
    }

    componentDidMount(){
      let t = +new Date()-60*60*24*1000*7
      this.setState({dateFrom: new Date(t), dateTo: new Date() })
    }
    
    handleDateChangeFrom = (d) => {
      this.setState({dateFrom: d})
      }

    handleDateChangeTo = (d) => {
      this.setState({dateTo: d})
      }

    render(){
      let dataForRender = []
      let dataForCalc = {}
      let iTime = 0
      const rate = 10*60*1000
      //отсеиваем по qr выбранного холодильника
      //console.log(this.props.base.logPing)
      this.props.base.logPing.map((itemLogPing, indexLogPing)=>{
        //if (indexLogPing%120===0){
          if(itemLogPing.qrf===this.props.selectedFridge.qr){
            itemLogPing.unixTime=Date.parse(itemLogPing.date)
            itemLogPing.dateMoment = moment(itemLogPing.unixTime).format('lll')
            iTime=Math.floor(itemLogPing.unixTime/rate)
            ///console.log(itemLogPing.unixTime)
            if(itemLogPing.unixTime>this.state.dateFrom&&itemLogPing.unixTime<this.state.dateTo){
              //dataForCalc=[ itemLogPing, ...dataForCalc]
              dataForCalc[iTime] = itemLogPing
            }
          }
        //}
      }
      )
      console.log(dataForCalc)
      let emptyObj = {}
      let i = Math.floor(this.state.dateFrom/rate)
      console.log(i)
      for ( i = (Math.floor(this.state.dateFrom/rate)); i<(Math.floor(this.state.dateTo/rate)); i++){  
        if (dataForCalc[i]){
          dataForRender=[...dataForRender, dataForCalc[i]]
        }else{
          emptyObj = {
            dateMoment: moment(i*rate).format('lll'), 
            temperature: 30,
            unixTime: i*rate
          }
          dataForRender=[...dataForRender, emptyObj]
        }
      }
      console.log(dataForRender)
      

  return (
    <Rect>
      <Top
        fridges={this.props.base.fridgeBase}
        onChangeFridge={(f)=>{this.props.onChangeFridge(f)}}
        selectedFridge={this.props.selectedFridge}
      />
      <div        
        className='top'
       
      />
        {/* тут рендерим график dataForRender по горизонтали временная шкала, по вертикали температура */}
<AreaChart  width={lay.window.width} height={lay.window.height-220} data={dataForRender}
  margin={{ top: 0, right: 30, left: 0, bottom: 0 }}>
  <defs>
    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
  </defs>
  <XAxis 
    dataKey="dateMoment" />
  <YAxis domain={[-10, 30]}/>
  <CartesianGrid strokeDasharray="3 2" />
  <Tooltip />
  <Area type="monotone" dataKey="temperature" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
</AreaChart>


        <Calendar>
          <Group2>
            <Date1Txt>Начало периода</Date1Txt>
            <DateTimePicker 
                onChange={(e) => { this.handleDateChangeFrom(e) }}
                value={this.state.dateFrom}
                locale={"ru-RU"}
                className='DateTimePicker'
                calendarIcon={ 
                  <IoniconsIcon
                    name="md-calendar"
                    style={{
                      color: "rgba(65,117,5,1)",
                      fontSize: 30,
                    }}
                  />}
            />
          </Group2>
          <Group2>
            <Date1Txt>Конец периода</Date1Txt>
              <DateTimePicker 
                onChange={(e) => { this.handleDateChangeTo(e) }}
                value={this.state.dateTo}
                locale={"ru-RU"}
                className='DateTimePicker'
                calendarIcon={<IoniconsIcon
                  name="md-calendar"
                  style={{
                    color: "rgba(117,5,5,1)",
                    fontSize: 30
                  }}
                />}
              />

          </Group2>
        </Calendar>
    </Rect>
  )
}}

const Rect = styled.div`
  display: flex;
  background-color: #E6E6E6;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100vw;
`;

const List = styled.div`
  display: flex;
  background-color: #999;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  height: calc (100vh - 80px);
  width: 100vw;
  overflow-y: scroll;
`;



const Calendar = styled.div`
  background-color: #efefefd6;
  height: 120px;
  width: 320px;
  position: fixed;
  bottom: 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;    
  display: flex;
  flex-direction: column;
  justify-content: space-around;

`;

const Group2 = styled.div`
  width: 300px;
  height: 44px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

const Date1Txt = styled.span`
  font-family: Roboto-300;
  color: #121212;
  font-size: 16px;
  width: 90px;
  text-align: right;
  margin-right: 8px;
`;

const GoodsName = styled.span`
  font-family: Roboto-700;
  color: #121212;
  width: 140px;
  font-size: 14px;
  height: 34px;
  text-align: center;
`;


const LoremIpsum = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 14px;
  width: 35px;
  text-align: right;
  margin-right: 10px;
`;

export default PingLog;
