import React, { Component } from "react";
import styled, { css } from "styled-components";
import CreeteQrStack from "../components/CreeteQrStack";
import QuickSetStack from "../components/QuickSetStack";
import Top from "../components/Top"
import {Context} from "../functions/context"
import KitSelect from "../components/KitSelect"


// экран с выпуском новой продукции
class QuickAccessKit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '',
      selectedKIt: 'Стандарт'
    }
  }

componentDidMount(){
  
}

onChangeKit=(k)=>{
  this.setState({selectedKIt: k })
  this.context.onChangeSelectedKIt(k)
}

  render(){

    let quickItems=[]
     this.context.base.quickAccessKit.map((item, index)=>{
      if ((item.idf === this.props.selectedFridge.idf)&&(item.kitGroup===this.context.selectedKIt)){
        quickItems = [...quickItems, item]
      }
    })

    let baseTree = {}
    this.context.base.productBase.map((item, index) => {
      baseTree[item.idp] = item
    })
    //console.log(sourcesTree)

    let t=+new Date()
  return (
    <Container
    style={{
          flexDirection: this.props.selectedTable?'column':'row',
          alignItems: this.props.selectedTable?'center':'flex-start',
        }}
     >
      <Top
        fridges={this.props.base.fridgeBase}
      />

      <KitSelect
        quickAccessKitGroups={this.props.base.quickAccessKitGroups}
        onChangeKit={(k)=>{this.onChangeKit(k)}}
        selectedKIt={this.context.selectedKIt}
      />

      <div        
        className='top'
       
      />
      <div        
        className='top'
       
      />
      {(this.props.selectedFridge.idf!='')?
        <CreeteQrStack
          style={{
            height: 200,
            width: 320,
            marginTop: 8
          }}
          productNamesList={this.props.base.productBase}
          update={(s)=>{this.props.update(s)}}
          idf={this.props.selectedFridge.idf}
          mode={'AddQuickAccessKit'}
          parent={'QuickAccessKit'}
          selectedKIt={this.context.selectedKIt}
        ></CreeteQrStack>
        :
        <h1 style={{margin: 40}}>{"Для начала следует выбрать нужный холодильник из перечня в шапке"}</h1>
      }
      {(this.props.selectedFridge.idf!='')&&
      <>
        {quickItems.map((item, index)=>{
          /// тут верстаем простой интерфейс, показывающий: товар, количество, срок, цену,
          /// + кнопки удалить и изменить
            return(
              <QuickSetStack
                key={item.idQuick}
                selectedTable={this.props.selectedTable}
                item={item}
                name={baseTree[item.idp].name}
                parent={'QuickAccessKit'}
                idf={this.props.selectedFridge.idf}
                update={(s)=>{this.props.update(s)}}
                selectedKIt={this.context.selectedKIt}
                style={{
                  height: this.props.selectedTable? 40: 200,
                  width: this.props.selectedTable? 340 : 160,
                  marginTop: 8,
                }}
              />
            )
          })
        }
      </>
      }
    </Container>
  );}
}

QuickAccessKit.contextType = Context

const Container = styled.div`
  display: flex;
  background-color: #E6E6E6;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100vw;
${'' /*   height: 100vh; */}
`;

const Rect = styled.div`
  display: flex;
  background-color: #E6E6E6;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  height: 100vh;
  width: 100vw;
`;

export default QuickAccessKit;
