import React, { Component } from "react";
import styled, { css } from "styled-components";

function CustomerLogInfo(props) {

  if (props.selectedTable){
  return (    
    <Container {...props}
    style={{
      flexDirection: 'row',
      ...props.style
    }}>
      <Group4>
        <GoodsName
          style={{cursor: 'pointer'}}
          onClick={()=>{props.onClickPhone(props.customer.phone)}}
        >{props.customer.phone}</GoodsName>
      </Group4>
        <div
          style={{
            display: 'flex',
            height: 40,
            backgroundColor: "rgba(247,245,193,1)",
            justifyContent: "center",
            alignItems: "center",
            width:240,
            cursor: 'pointer'
          }}
          onClick={()=>{props.onClickAdddress(props.fridge.addres)}}
        >{props.fridge.addres}</div>
      
        <div
        style={{
            display: 'flex',
            height: 40,
            backgroundColor: " rgba(206,236,178,1)",
            justifyContent: "center",
            alignItems: "center",
            width:240,
            cursor: 'pointer'
          }}
          onClick={()=>{props.onClickProduct(props.product&&props.product.name)}}
          >{props.product&&props.product.name}</div>
     
        <div
        style={{
            display: 'flex',
            height: 40,
            backgroundColor: "rgba(247,245,193,1)",
            justifyContent: "center",
            alignItems: "center",
            width:40
          }}
          >{props.source.price}</div>
     
        <LoremIpsum style={{height:50, width: 90, paddingTop: 8}}>{props.item.date}</LoremIpsum>
      
    </Container>)
  }else{
  return (
    <Container {...props}>
      <Group4
        style={{cursor: 'pointer'}}
        onClick={()=>{props.onClickPhone(props.customer.phone)}}
      >
        <GoodsName>{props.customer.phone}</GoodsName>
      </Group4>
      <Group3 
        style={{height:60, cursor: 'pointer'}}
        onClick={()=>{props.onClickAdddress(props.fridge.addres)}}
      >
        <LoremIpsum style={{height:50}}>{props.fridge.addres}</LoremIpsum>
      </Group3>
      <Group2  
        style={{height:60, cursor: 'pointer'}}
        onClick={()=>{props.onClickProduct(props.product&&props.product.name)}}
        >
        <LoremIpsum style={{height:50}}>{props.product&&props.product.name}</LoremIpsum>
      </Group2>
      <Group3>
        <LoremIpsum>{props.source.price}</LoremIpsum>
      </Group3>
      <Group>
        <LoremIpsum>{props.item.date}</LoremIpsum>
      </Group>
    </Container>
  );}
}

const Container = styled.div`
  display: flex;
  border-radius: 8px;
  margin-top: 8px;
  margin: 4px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(206,236,178,1);
  box-shadow: 2px 2px 8px 1px #0a190375;
`;

const Group4 = styled.div`
  flex-direction: column;
  width: 140px;
  justify-content: center;
  flex: 1 1 0%;
  display: flex;
`;

const GoodsName = styled.span`
  font-family: Roboto-700;
  color: #121212;
  width: 140px;
  font-size: 14px;
  height: 14px;
  text-align: center;
`;

const Group3 = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 0%;
  background-color: rgba(247,245,193,1);
  width: 160px;
  display: flex;
`;


const Group2 = styled.div`
  width: 160px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 0%;
  background-color: rgba(206,236,178,1);
  display: flex;
`;


const Group = styled.div`
  width: 160px;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1 0%;
  align-items: center;
  display: flex;
`;


const LoremIpsum = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 14px;
  width: 160px;
  text-align: right;
  margin-right: 10px;
`;

export default CustomerLogInfo;
