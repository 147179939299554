import React, { Component } from "react";
import ImageUploading from "react-images-uploading";


export default function ImageUploader(props) {
const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  return (
    <div>
      <ImageUploading
        multiple = {false}
        value={props.value}
        onChange={props.onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button 
            //className="margin16"
              style={
                  {color: isDragging ? "red":'#999',
                  height: 120,
                  } 
                }
              onClick={onImageUpload}
              {...dragProps}
            >
              Нажмите или перетащите изображение в эту область
            </button>
           {/*  &nbsp; */}
            <button 
            //className="margin16" 
            style={{width: 316}} onClick={onImageRemoveAll}>Удалить все</button>
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img className="margin16" style={{width: 150}} src={image.data_url} alt="" width="150" />
                <div  className="image-item__btn-wrapper">
                  <button className="margin16" style={{width: 100}} onClick={() => onImageUpdate(index)}>Сменить</button>
                  <button className="margin16" style={{width: 100}} onClick={() => onImageRemove(index)}>Удалить</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
 
  
}
