import React from "react";
//import { BrowserRouter as Router, Route } from "react-router-dom";

import styled, { css } from "styled-components";
import "./icons.js";
import LogIn from "./screens/LogIn";
import NewGoods from "./screens/NewGoods";
import QuickAccessKit from "./screens/QuickAccessKit"
import NewMenuItem from "./screens/NewMenuItem";
import Fridges from "./screens/Fridges"
import Statistic from "./screens/Statistic";
import CustomersLog from "./screens/CustomersLog"
import SalesStat from "./screens/SalesStat"
import PingLog from "./screens/PingLog"
import Print from "./screens/Print"
import Showcase from "./screens/Showcase"
import Header from "./components/Header";
import "./style.css";
import axi from "./functions/axiosf";
import {Context} from "./functions/context"


import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';


import { Bounce } from 'react-activity/lib/Bounce';
//import 'react-activity/lib/Bounce/Bounce.css';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      screen: 'LogIn',
      route: this.route(),
      token: '',
      //update: this.axiUpdate,
      menuOpenStatus: 0,
      menuOpenStatusToggle: this.menuOpenStatusToggle(),
      base: [],
      baseTree: {},
      sourceToPrint: [], 
      selectedFridge: {
        idf: '', 
        qrf: '',
        addres: 'Выберете холодильник'
      },
      onChangeFridge: this.onChangeFridge,
      baseFridges: {},
      selectedTable: false,
      selectedKIt: 'Стандарт',
      onChangeSelectedKIt: this.onChangeSelectedKIt,
    }
  }

  componentDidMount(){
    if (this.state.token = '') { this.setState({ screen: 'LogIn'})}
  }

  route=(u)=>{
    this.setState({screen: u})
  }

  menuOpenStatusToggle=(u)=>{
    this.setState({ menuOpenStatus: u })
  }

  addToken=(u, s)=>{
    this.setState({ token: u})
    this.axiUpdate(s, u)
  }


  axiUpdate=(s, tok=this.state.token)=>{
    this.setState({ screen: 'wait'})
    axi("loginAdmin.php", null, { token: tok}).then((result) => {
      if (result.type == 'approved') {
        let baseTree = {}
        result.base.productBase.map((item, index) => {
          baseTree[item.idp] = item
        })
        let baseFridges = {}
        result.base.fridgeBase.map((itemf, indexf) => {
          baseFridges[itemf.idf] = itemf
        })
          this.setState({ base: result.base, baseTree: baseTree, baseFridges: baseFridges, screen: s})
      } else {
        this.setState({ screen: 'LogIn' })
      }
    }, (e) => { console.log(e) })
  }

  onChangeFridge=(f)=>{this.setState({selectedFridge: f})}
  onChangeSelectedKIt =(k)=>{
    this.setState({selectedKIt: k })
  }
  render(){

    return (
      <Context.Provider value={this.state}>
      <Container>
        {
          this.state.screen == 'wait' &&
          <div
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#33485c',
              flexDirection: 'column'
            }}
          >
            <Dots color="#727981" size={100} speed={1} animating={true} />
            <h3 style={{color: "#fff", padding: 30}}>дождитесь загрузки данных</h3>
          </div>
        }
        { this.state.screen == 'LogIn' &&
          <LogIn {...this.state} 
            route={(u) => this.route(u)} 
            addToken={(u, s) => { this.addToken(u, s)} } 
    
            />}
        { this.state.screen == 'Statistic' && 
          <Statistic {...this.state} 
            update={(s) => { this.axiUpdate(s) }}
            onChangeFridge={(f)=>{this.setState({selectedFridge: f})}}
          /> }
        { this.state.screen == 'CustomersLog' && 
          <CustomersLog {...this.state} 
            update={(s) => { this.axiUpdate(s) }}
            onChangeFridge={(f)=>{this.setState({selectedFridge: f})}}
          /> }
        { this.state.screen == 'SalesStat' && 
          <SalesStat {...this.state} 
            update={(s) => { this.axiUpdate(s) }}
            onChangeFridge={(f)=>{this.setState({selectedFridge: f})}}
          /> }
        { this.state.screen == 'PingLog' && 
          <PingLog {...this.state} 
            update={(s) => { this.axiUpdate(s) }}
            onChangeFridge={(f)=>{this.setState({selectedFridge: f})}}
          /> }
        { this.state.screen == 'Showcase' && 
          <Showcase {...this.state} 
            update={(s) => { this.axiUpdate(s) }}  

          />}
        { this.state.screen == 'NewMenuItem' && 
          <NewMenuItem {...this.state} 
            update={(s) => { this.axiUpdate(s) }}  

          />}
        { this.state.screen == 'NewGoods' && 
          <NewGoods 
            {...this.state} 
            //key={+this.state.selectedFridge.idf}
            update={(s) => { this.axiUpdate(s) }} 
            toPrint={(sourceToPrint) => { 
             this.setState({ 
               screen: 'Print', 
               sourceToPrint: sourceToPrint
               })
               }
              }
            onChangeFridge={(f)=>{this.setState({selectedFridge: f})}}   
               /> 
        }
        { this.state.screen == 'QuickAccessKit' && 
          <QuickAccessKit 
            {...this.state} 
            update={(s) => { this.axiUpdate(s) }} 
            onChangeFridge={(f)=>{this.setState({selectedFridge: f})}}   
               /> 
        }
        { this.state.screen == 'Fridges' && 
          <Fridges 
            states={this.state} 
            update={(s) => { this.axiUpdate(s) }}
          />}
        { this.state.screen == 'Print' && 
          <Print {...this.state} route={(u) => this.route(u)} />}
        {  this.state.screen != 'LogIn' && this.state.screen != 'Print' &&  
       
          <Header
            {...this.state} 
            route={(u) => this.route(u)}
            menuOpenStatusToggle={(u) => this.menuOpenStatusToggle(u)}
            selectedTable={this.state.selectedTable}
            toggleTable={()=>this.setState({selectedTable: !this.state.selectedTable})}
          ></Header>  
        }    
      </Container>
      </Context.Provider>
    );
  }
}

const Container = styled.div`
  display: flex;
  float:none;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100vh;
  width: 100vw;
`;
const HeaderComponentStack = styled.div`
  top: 0px;
  left: 0px;
  width: 160px;
  height: 0px;
  position: fixed;
  z-index: 250;
  overflow-y: visible;
`;

export default App;

      {/*
      <Route path="/" exact component={RootScreen} />
       <Route path="/NewGoods/" exact component={NewGoods} />
      <Route path="/NewMenuItem/" exact component={NewMenuItem} />
      <Route path="/Statistic/" exact component={Statistic} /> 
      */}    
  /*     
    if (this.state.screen == 'NewGoods'){
      return (
        <NewGoods
        props={this.state}>
        </NewGoods>
      );
    }
    if (this.state.screen == 'NewMenuItem') {
      return (
        <NewMenuItem
          props={this.state}>
        </NewMenuItem>
      );
    } 
    if (this.state.screen == 'Statistic') {
      return (
        <Statistic
          props={this.state}>
        </Statistic>
      );
    } 
  */