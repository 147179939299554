import React, { Component } from "react";
import styled, { css } from "styled-components";
import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";
import axi from "../functions/axiosf"
import moment from 'moment';
import { Context } from "../functions/context";
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

class ShowcaseItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ismMenuOpen: false,
      isShowMoveTo: false,
      moveTo: {idf: ''},
      //isSelected: false
    }
  }

  moveItem = (method) => {

    let params = {
      token: this.context.token,
      product: this.props.item,
      moveTo: this.state.moveTo,
    }
    axi("showcase.php", method, params).then((result) => {
      if (result.type == 'approved') {
        switch (method){
          case 'delete':
            alert(this.props.item.idSource+' безвозвратно удалён из базы')
            break
          case 'moveOut':
            alert(this.props.item.idSource+' списан на склад')
            break
          case 'moveTo':
            alert(this.props.item.idSource+' успешно перемещён на ' +this.state.moveTo.addres)
            break
        }
        
        this.props.update('Showcase')
      } else {
        console.log(result)
      }
    }, (e) => { console.log(e) })

  }
 

  render(){
    const item = this.props.item
    const baseTree = this.context.baseTree
  return (
    <Container
      style={{
        backgroundColor: (+item.timeLost * 1000 < +new Date())?'#9026':'rgb(220 218 218)',
        flexDirection: this.context.selectedTable?'row':'column'
        }}
    >
      <Checkbox
                    checked={this.props.selected}
                    onChange={()=>this.props.changeCheckBox(!this.props.selected)}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
      <SubContainer
      style={{
        flexDirection: this.context.selectedTable?'row':'column',
        alignItems: this.context.selectedTable?'center':'flex-start'
        }}
                 onClick={()=>{
                  if(!this.state.isShowMoveTo)
                    {this.setState({ismMenuOpen: !this.state.ismMenuOpen})}
                  }}>
                {/* !this.context.selectedTable&&
                <img src={"http://qrcoder.ru/code/?" + item.qr +"&6&0"} 
                  width="144" height="144" border="0" title="QR код"
                    style={{
                      transition: '1s', 
                      height: (this.state.ismMenuOpen)?0:144
                      }}
                  />
                 */}
                 
                <p
                style={{
                  fontSize: 14,
                  margin: 4
                }}>ID: {item.idSource}</p>
                <p
                style={{
                  width: 136,
                  fontSize: 10,
                  margin: 4}}
                >{baseTree[item.idp].name}</p>
                {!this.context.selectedTable&&
                <p
                  style={{
                    width: 136,
                    fontSize: 10,
                    margin: 4
                  }}
                >{baseTree[item.idp].val}</p>
                }
                <p
                style={{
                  fontSize: 10,
                  margin: 4
                }}>годен до: </p>
              <p
                style={{
                  fontSize: 10,
                  margin: 4
                }}>{moment(+item.timeLost * 1000).format('lll')}</p>
                {!this.context.selectedTable&&
                <p
                style={{
                  width: 136,
                  fontSize: 14,
                  margin: 4
                }}>цена: {item.price} руб.</p>
                }
                {!this.context.selectedTable&&
                <p
                style={{
                  width: 136,
                  fontSize: 10,
                  margin: 4
                }}>{this.context.baseFridges[item.idf].addres}</p>
                }
      </SubContainer>
      {!this.context.selectedTable&&
                <div
                  style={{
                    transition: '1s', 
                    height: (this.state.ismMenuOpen&&!this.state.isShowMoveTo)?130:0,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'column',
                    overflow: 'hidden',
                  }}
                >
                  <Button variant="contained" color="primary"
                  onClick={()=>{
                    this.setState({isShowMoveTo: true})
                  }}
                  >
                    Переместить
                  </Button>
                  <Button variant="contained" color="secondary"
                  onClick={()=>{
                    this.moveItem('moveOut')
                    this.setState({isShowMoveTo: false, ismMenuOpen: false})
                  }}>
                    Списать
                  </Button>
                  <Button variant="contained" color="secondary"
                  onClick={()=>{
                    this.moveItem('delete')
                    this.setState({isShowMoveTo: false, ismMenuOpen: false})
                  }}>
                    Удалить
                  </Button>
                </div>
      }
      {!this.context.selectedTable&&
                <div
                  style={{
                    transition: '1s',                    
                    height: (this.state.ismMenuOpen&&this.state.isShowMoveTo)?150:0,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'column',
                    overflow: 'hidden',
                  }}
                >

                  <FormControl
                    variant='outlined' >
                    <InputLabel id="demo-simple-select-outlined-label">Адрес</InputLabel>
                    <Select
                      label="Адрес"
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={this.state.moveTo.idf}
                      style={{width: 130}}
                      onChange={
                        (e)=>{
                          this.setState({moveTo: this.context.baseFridges[e.target.value]})
                          }
                        }
                    >
                      {this.context.base.fridgeBase.map((item, index)=>{
                        return(
                          <MenuItem key={index} value={item.idf}>{item.addres}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <Button variant="contained" color="primary"
                  onClick={()=>{
                    this.moveItem('moveTo')
                    this.setState({isShowMoveTo: false, ismMenuOpen: false})
                  }}
                  >
                    Отправить
                  </Button>
                  <Button variant="contained" color="secondary"
                  onClick={()=>{
                    this.setState({isShowMoveTo: false})
                  }}>
                    Отмена
                  </Button>
                </div>
        }
              
      </Container>     
  )}
}

const Container = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 2px 2px 8px 1px #0a190375;
  overflow: hidden;
  justify-content: space-between;
  margin: 4px;
`;

const SubContainer = styled.div`
  display: flex;
`
ShowcaseItem.contextType = Context
export default ShowcaseItem;
