import React, { Component } from "react";
import styled, { css } from "styled-components";
import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";
import axi from "../functions/axiosf"

import DateTimePicker from 'react-datetime-picker';

class CreeteQrStack  extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '123456789',
      idSelected: '',
      name: '',
      num: '10',
      price: '',
      timeStart: 0,
      timeEnd: 0,
      expiration: '48',
      isSaved: false,
      selectionProduct: [],
    }
  }

  componentDidMount(){
    let t = +new Date()
    let tEnd = new Date(t+(+this.state.expiration*3600000))
    this.setState({ timeStart: new Date(), timeEnd: tEnd})
  }

  startDayAdd=(d)=>{
    let tEnd = new Date(+new Date(d)+this.state.expiration * 3600000)
    this.setState({ timeStart: d, timeEnd: tEnd })
  }

  finishDayAdd=(d)=>{
    let exp = ((+new Date(d))-(+new Date(this.state.timeStart)))/3600000
    this.setState({ expiration: exp, timeEnd: d })
  }

  setExp=(e)=>{
    let tEnd = new Date((+new Date(this.state.timeStart) + (+e * 3600000)))
    this.setState({ expiration: e, timeEnd: tEnd })
  }

  _textChangeSelectionCity = (value) => {
    let includes = []
    if (value != '') {
      this.props.productNamesList.map((item, index) => {
        if (item.name.toLowerCase().includes(value.toLowerCase())) {
          includes = [...includes, item]
        }
      })
    }

    this.setState({
      selectionProduct: includes,
      name: value
    })
  }

  _textSelectedCity = (item) => {
    this.setState({
      name: item.name,
      price: item.price,
      idSelected: item.idp,
      selectionProduct: []
    })
    this.setExp( item.expiration )
  }

  createQRs = () => {
    let params = {
      ...this.state,
      tStart: +new Date(this.state.timeStart),
      tFinish: +new Date(this.state.timeEnd),
      idf: +this.props.idf,
    }
    if (this.props.selectedKIt){params.kitGroup=this.props.selectedKIt}
    axi("createQRs.php", this.props.mode, params).then((result) => {
      if (result.type == 'approved') {
        this.setState({ idSelected: '', name: ''})
        this.props.update(this.props.parent)
      } else {
        //this.setState({ screen: 'LogIn' })
        console.log(result)
      }
    }, (e) => { console.log(e) })

  }

  render(){
    return (
    <Container style={this.props.style}>
      <Group>
        <TextInput placeholder="Название продукта"
          onChange={(e) => { this._textChangeSelectionCity(e.target.value) }}
          value={this.state.name}
        ></TextInput>
          
        <TextInput2 placeholder="20"
          onChange={(e) => { this.setState({ num: e.target.value }) }}
          value={this.state.num}
        ></TextInput2>
        <VolTxt>шт.</VolTxt>
        <TextInput6 placeholder="200"
          onChange={(e) => { this.setState({ price: e.target.value }) }}
          value={this.state.price}>
        </TextInput6>
        <RurTxt>Руб.</RurTxt>
      </Group>
      <Group2>
      <div
            className={'selectionCityCont'}
            style={{ maxHeight: document.body.clientWidth > 1240 ? 0 : 600 }}
          >
            {this.state.selectionProduct.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: 300,
                    
                    marginTop: 3,
                    display: "flex",
                    alignItems: 'center',
                    backgroundColor: (this.state.cityName == item.name) && '#ffcb17',
                  }}
                  className={'selectionCityDiv'}
                  onClick={() => {
                    this._textSelectedCity(item)
                  }}
                >
                  <p style={{}}
                    className={'selectionCityText'}
                    style={{ color: (this.state.cityName == item.name) && '#000' }}
                  >{item.name}</p>
                </div>
              )
            })}
          </div>
        <Date1Txt>Дата выпуска</Date1Txt>
        <DateTimePicker 
            onChange={(e) => { this.startDayAdd(e) }}
            value={this.state.timeStart}
            locale={"ru-RU"}
            className='DateTimePicker'
            calendarIcon={ 
              <IoniconsIcon
                name="md-calendar"
                style={{
                  color: "rgba(65,117,5,1)",
                  fontSize: 30,
                }}
              />}
        />
      </Group2>
      <Group2>
        <Date1Txt>Годен до</Date1Txt>
          <DateTimePicker 
            onChange={(e) => { this.finishDayAdd(e) }}
            value={this.state.timeEnd}
            locale={"ru-RU"}
            className='DateTimePicker'
            calendarIcon={<IoniconsIcon
              name="md-calendar"
              style={{
                color: "rgba(117,5,5,1)",
                fontSize: 30
              }}
            />}
          />

      </Group2>
      <Group4>
        <PeriodTxt>Срок годности, час</PeriodTxt>
        <TextInput3 placeholder="48"
            onChange={(e) => { this.setExp( e.target.value ) }}
            value={this.state.expiration}
        ></TextInput3>
          {this.state.idSelected != '' && this.state.price != '' &&
          <>
          {!this.state.isSaved?
          <IoniconsIcon
            name="ios-save"
            style={{
              color: "rgba(46,95,151,1)",
              fontSize: 40,
              cursor: 'pointer'
            }}
            onPress={() => this.createQRs()}
          />
          :
          <IoniconsIcon
            name="md-print"
            style={{
              color: "rgba(46,95,151,1)",
              fontSize: 40
            }}
          />
          }
         </>
        }
      </Group4>
    </Container>
  );}
}

const Container = styled.div`
  display: flex;
  background-color: rgb(220 218 218);
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 2px 2px 8px 1px #0a190375;
`;

const Group = styled.div`
  width: 300px;
  height: 30px;
  flex-direction: row;
  align-items: center;
  left: 10px;
  top: 5px;
  justify-content: space-between;
  display: flex;
`;

const TextInput = styled.input`
  font-family: Roboto-300;
  color: rgba(8,8,8,1);
  height: 30px;
  width: 142px;
  border-radius: 8px;
  background-color: #6e95c1a8;
  text-align: center;
  border: none;
  box-shadow: inset 2px 2px 4px 1px #0a190375;
`;

const TextInput2 = styled.input`
  font-family: Roboto-300;
  color: #121212;
  height: 30px;
  width: 37px;
  background-color: #6e95c1a8;
  border-radius: 8px;
  text-align: center;
  border: none;
  box-shadow: inset 2px 2px 4px 1px #0a190375;
`;

const VolTxt = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 20px;
  width: 24px;
  text-align: left;
`;

const TextInput6 = styled.input`
  font-family: Roboto-300;
  color: #121212;
  height: 30px;
  width: 37px;
  background-color: #6e95c1a8;
  border-radius: 8px;
  text-align: center;
  left: 226px;
  top: 0px;
  border: none;
  box-shadow: inset 2px 2px 4px 1px #0a190375;
`;

const RurTxt = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 16px;
  width: 29px;
  text-align: left;
  left: 271px;
  top: 5px;
  align-self: center;
`;

const Group2 = styled.div`
  width: 300px;
  height: 44px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

const Date1Txt = styled.span`
  font-family: Roboto-300;
  color: #121212;
  font-size: 16px;
  width: 90px;
  text-align: right;
  margin-right: 8px;
`;


const Group4 = styled.div`
  width: 300px;
  height: 44px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const PeriodTxt = styled.span`
font-family: Roboto-300;
  color: #121212;
  height: 17px;
  width: 150px;
  text-align: right;
`;

const TextInput3 = styled.input`
  font-family: Roboto-300;
  color: rgba(8,8,8,1);
  height: 30px;
  width: 43px;
  border-radius: 8px;
  background-color: #6e95c1a8;
  text-align: center;
  border: none;
  box-shadow: inset 2px 2px 4px 1px #0a190375;
`;

export default CreeteQrStack;
