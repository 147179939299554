import React from "react";
import styled, { css } from "styled-components";
import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";
import axi from "../functions/axiosf"

import DateTimePicker from 'react-datetime-picker';

class CreateQrQuickStack  extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '123456789',
      timeStart: 0,
      timeEnd: 0,
      isSaved: false,
    }
  }

  componentDidMount(){
    let t = +new Date()

    this.setState({ timeStart: new Date()})
  }

  startDayAdd=(d)=>{
    //let tEnd = new Date(+new Date(d)+this.state.expiration * 3600000)
    this.setState({ timeStart: d})
  }


///////
  finishDayAdd=(d)=>{
    let exp = ((+new Date(d))-(+new Date(this.state.timeStart)))/3600000
    this.setState({ expiration: exp, timeEnd: d })
  }
//////
  setExp=(e)=>{
    let tEnd = new Date((+new Date(this.state.timeStart) + (+e * 3600000)))
    this.setState({ expiration: e, timeEnd: tEnd })
  }



  createQRs = (fridgeKit) => {
    let baseTree = {}
    this.props.productNamesList.map((item, index) => {
        baseTree[item.idp] = item
      })


    let completeKit = []
    fridgeKit.map((item, index)=>{
      let tEnd = new Date((+new Date(this.state.timeStart) + (+item.exp * 3600000)))

      let itemOfKit = {
        idSelected: +item.idp,
        name: baseTree[item.idp].name,
        num: +item.num,
        price: +item.price,
        timeStart: this.state.timeStart,
        timeEnd: tEnd,
        expiration: item.exp,
        tStart: +new Date(this.state.timeStart),
        tFinish: +new Date(tEnd),
      }
      completeKit = [...completeKit, itemOfKit]
    })

    let params = {
      token: this.props.token,
      completeKit: completeKit,
      idf: +this.props.idf
    }
    axi("createQRs.php", this.props.mode, params).then((result) => {
      if (result.type == 'approved') {
        this.setState({ idSelected: '', name: ''})
        this.props.update(this.props.parent)
      } else {
        //this.setState({ screen: 'LogIn' })
        console.log(result)
      }
    }, (e) => { console.log(e) })

  }

  render(){
    let fridgeKit = []
    let sumOfKit = 0
    this.props.kits.map((item, index)=>{
      if (item.idf==this.props.idf){
        sumOfKit = sumOfKit + +item.num
        fridgeKit=[...fridgeKit, item]
      }
    })
    return (
    <Container style={this.props.style}>
      <Group>
        <Date1Txt><b>{'Быстрый '+ this.props.kitGroupName}</b></Date1Txt>
      </Group>
      <Group>
        <Date1Txt
          style={{fontSize: 14}}
        >{fridgeKit.length+' наименований, ' + sumOfKit + ' товаров'}</Date1Txt>
      </Group>
      <Group>
        <Date1Txt>Дата выпуска</Date1Txt>
      </Group>
      <Group2>
        
        <DateTimePicker 
            onChange={(e) => { this.startDayAdd(e) }}
            value={this.state.timeStart}
            locale={"ru-RU"}
            className='DateTimePicker'
            calendarIcon={ 
              <IoniconsIcon
                name="md-calendar"
                style={{
                  color: "rgba(65,117,5,1)",
                  fontSize: 30,
                }}
              />}
        />
      </Group2>
      <Group4>  
          <IoniconsIcon
            name="ios-save"
            style={{
              color: "rgba(46,95,151,1)",
              fontSize: 40,
              cursor: 'pointer'
            }}
            onPress={() => this.createQRs(fridgeKit)}
          />    
      </Group4>
    </Container>
  );}
}

const Container = styled.div`
  display: flex;
  background-color: rgb(220 218 218);
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 2px 2px 8px 1px #0a190375;
`;

const Group = styled.div`
  width: 200px;
  height: 30px;
  flex-direction: row;
  align-items: center;
  left: 10px;
  top: 5px;
  justify-content: space-between;
  display: flex;
`;

const Group2 = styled.div`
  width: 200px;
  height: 44px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

const Date1Txt = styled.span`
  font-family: Roboto-300;
  color: #121212;
  width: 230px;
  font-size: 16px;
  text-align: right;
  margin-right: 8px;
`;


const Group4 = styled.div`
  width: 200px;
  height: 44px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

export default CreateQrQuickStack;
