import React, { Component } from "react";
import styled, { css } from "styled-components";
import axi from '../functions/axiosf';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export default function TopTable(props) {

 

    return (
     <div 
        style={{
          position: "fixed",
          top: 80,
          zIndex: 200,
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          height: 60,
          overflowX: 'scroll'
        }}>
        <div 
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: 720,
            alignItems: 'center',
            height: 40
          }}>
          <div
            style={{
              display: 'flex',
              height: 40,
              backgroundColor: "rgba(206,236,178,1)",
              justifyContent: "center",
              alignItems: "center",
              width: 140
            }}
          >
            {props.column1}
          </div>
          <div
            style={{
              display: 'flex',
              height: 40,
              backgroundColor: "rgba(247,245,193,1)",
              justifyContent: "center",
              alignItems: "center",
              width: 220,
              textAlign: "center"
            }}
          >{props.column2}</div>
        
          <div
          style={{
              display: 'flex',
              height: 40,
              backgroundColor: "rgba(206,236,178,1)",
              justifyContent: "center",
              alignItems: "center",
              width: 220,
              textAlign: "center"
            }}
            >{props.column3}</div>
      
          <div
          style={{
              display: 'flex',
              height: 40,
              backgroundColor: "rgba(247,245,193,1)",
              justifyContent: "center",
              alignItems: "center",
              width: 60
            }}
            >{props.column4}</div>
          <div
          style={{
            display: 'flex',
            height: 40,
            backgroundColor: "rgba(206,236,178,1)",
            justifyContent: "center",
            alignItems: "center",
            width:90,
            textAlign: "center"
          }}
        >
        {props.column5}
      </div>
        </div>
    </div>
    );
  
}
