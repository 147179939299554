import React, { Component } from "react";
import styled, { css } from "styled-components";
import NewGood from "../components/NewGood";
import GoogFullStatus from "../components/GoogFullStatus";
import {lay} from "../constants/Layout"
import TopSelectMenuItem from "../components/TopSelectMenuItem"

class NewMenuItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '',
      selectedItem: {},
      selectedIndex: 0
    }
  }

  render(){

    let ids = []
    let sourcesTree = {}

    this.props.base.sources.map((item, index) => {
      if (ids.indexOf(item.idp) == -1) {
        ids = [...ids, item.idp]
        sourcesTree[item.idp] = []
      }
      sourcesTree[item.idp] = [...sourcesTree[item.idp], item]
    })

    let baseTree = {}
    this.props.base.productBase.map((item, index) => {
      baseTree[item.idp] = item
    })


    console.log(sourcesTree)


    return (
    <Container>
      <TopSelectMenuItem
        menuItems={this.props.base.productBase}
        onChangeItem={(f)=>{
          this.setState({
            selectedItem: f,
            selectedIndex: +f.idp
            })
          }}
        selectedItem={this.state.selectedItem}

      />      
      {this.state.selectedIndex&&sourcesTree[this.state.selectedIndex]?
      <GoogFullStatus
              idp={this.state.selectedIndex}
              //key={this.state.selectedIndex}
              sourcesTree={sourcesTree[this.state.selectedIndex]}
              productBase={baseTree}
              style={{
                width: 160,
                height: 160,
                marginTop: 90,
              }}
              
            />:      
            <div        
              className='top'
            
            />
            }
      <NewGood
        key={this.state.selectedIndex}
        style={{
          height: "auto",
          //marginTop: this.state.selectedIndex? (lay.window.width>680?90:16):90,
          marginTop: this.state.selectedIndex? 16:90,
          marginBottom: 16,
          width: lay.window.width>680? lay.window.width :340,
          flexDirection: lay.window.width>680?'row':'column',
          flexWrap: lay.window.width>680?'wrap':'nowrap'
        }}
        update={(s) => { 
          this.props.update(s)
          this.setState({
            selectedItem: {},
            selectedIndex: 0})
          }}
        token = {this.props.token}
        selectedItem = {this.state.selectedItem}
        selectedIndex={this.state.selectedIndex}
      />

    </Container>
  );}
}

const Container = styled.div`
  display: flex;
  background-color: #E6E6E6;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
`;

export default NewMenuItem;
