import React, { Component } from "react";
import styled, { css } from "styled-components";
import NewFridge from "../components/NewFridge";
import EditFridge from "../components/EditFridge"
//import FridgeItem from "../components/FridgeItem"

function Fridges(props) {
  return (
    <Container>
      <NewFridge
        style={{
          height: 400,
          width: 320,
        }}
        update={(s) => { props.update(s) }}
        token={props.states.token}
      ></NewFridge>

      {props.states.base.fridgeBase.map((item, index)=>{
        return(
          <EditFridge 
            style={{
              height: 400,
              width: 320,
            }}
            item={item} 
            key={index}
            token={props.states.token}
            update={(s) => { props.update(s) }}
            />
        )
      })}
      
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-color: #E6E6E6;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  height: 100vh;
  width: 100vw;
`;


export default Fridges;
