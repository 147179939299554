import React, { Component } from "react";
import styled, { css } from "styled-components";
import CreeteQrStack from "../components/CreeteQrStack";
import CreateQrQuickStack from "../components/CreateQrQuickStack"
import ShowcaseItem from "../components/ShowcaseItem";
import Top from "../components/Top"
import {Context} from "../functions/context"
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import axi from "../functions/axiosf"

// экран с витриной холодильника
class Showcase extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '',
      selectedSources: [],
      sourcesTree: [],
      idf: '',
      moveTo: {idf: ''},
      progress: 0,
    }
  }

  componentDidMount(){
    this._parseShowcase()
  }


  _parseShowcase=(idfr=this.context.selectedFridge.idf)=> {
    console.log(idfr)
    let st = []
    this.context.base.sources.map((item, index)=>{
      if (item.idf === idfr&&item.stage==="in_sale"){
        st = [...st, item]
      }
    
    })
    let sS=[]
    this.setState({sourcesTree: st, idf: idfr, selectedSources: sS})
  }

moveItem = (method) => {
  let IDs = ''
  const groupMove = 
  Promise.all( 
    this.state.selectedSources.map(async (itemAxi, indexAxi) => 
      {
        let params = {
          token: this.context.token,
          product: itemAxi,
          moveTo: this.state.moveTo,
        }
        await axi("showcase.php", method, params).then((result) => {
          if (result.type == 'approved') {
            IDs = IDs+itemAxi.idSource+', '
            this.setState({progress: this.state.progress+1})
            return (IDs)
          } else {
            console.log(result)
          }
        }, (e) => { console.log(e) })
      })
    ).then((results)=>{    
      console.log(results)
          switch (method){
            case 'delete':
              alert(IDs+' безвозвратно удалёны из базы')
              break
            case 'moveOut':
              alert(IDs+' списаны на склад')
              break
            case 'moveTo':
              alert(IDs+' успешно перемещены на ' +this.state.moveTo.addres)
              break
          }
          this.props.update('Showcase')
        })
    
  }

  render(){
  let process = this.state.progress/this.state.selectedSources.length*100
  let t=+new Date()
  return (
    <Container
    style={{
          flexDirection: this.context.selectedTable?'column':'row',
          alignItems: this.context.selectedTable?'center':'flex-start',
        }}
     >
      <Top
        fridges={this.context.base.fridgeBase}
        onChangeFridge = {(value)=>{
          this._parseShowcase(value.idf)
        }}
      />
      <div        
        className='top'
       
      />
      {(this.context.selectedFridge.idf!='')?
      <>
        <div style={{
            width: this.state.selectedSources.length>0 ? 300 : 0,
            height: this.state.selectedSources.length>0 ? 200 : 0,
            padding: this.state.selectedSources.length>0 ? 10 : 0,
            display: 'flex',
            flexDirection: "column",
            alignItems: "center",
            transition: '1s'
          }}>
          {this.state.selectedSources.length>0?
            <>
            <div>{'групповая обработка для '+this.state.selectedSources.length+' позиции'}</div>
                <div
                  style={{
                    transition: '1s',                    
                    height: 70,
                    width: 300,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'row',
                    overflow: 'hidden',
                  }}
                >

                  <FormControl
                    variant='outlined' >
                    <InputLabel id="demo-simple-select-outlined-label">Адрес</InputLabel>
                    <Select
                      label="Адрес"
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={this.state.moveTo.idf}
                      style={{width: 130}}
                      onChange={
                        (e)=>{
                            this.setState({moveTo: this.context.baseFridges[e.target.value]})
                          }
                        }
                    >
                      {this.context.base.fridgeBase.map((item, index)=>{
                        return(
                          <MenuItem key={index} value={item.idf}>{item.addres}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <Button variant="contained" color="primary"
                    style={{
                      transition: '1s',
                    }}
                    disabled={(this.state.moveTo.idf==='')?true:false}
                    onClick={()=>{
                      this.moveItem('moveTo')
                    }}
                  >
                    Переместить
                  </Button>

                </div>
                <div
                  style={{
                    transition: '1s', 
                    height: 70,
                    width: 250,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'row',
                    overflow: 'hidden',
                  }}
                >
                  <Button variant="contained" color="secondary"
                  onClick={()=>{
                    this.moveItem('moveOut')
                  }}>
                    Списать
                  </Button>
                  <Button variant="contained" color="secondary"
                  onClick={()=>{
                    this.moveItem('delete')
                  }}>
                    Удалить
                  </Button>
                </div>
                <Box display="flex" alignItems="center">
                  <Box width={250} mr={1}>
                    <LinearProgress variant="determinate" value={process} />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">{`${Math.round(
                      process,
                    )}%`}</Typography>
                  </Box>
                </Box>
              </>
            : 
          <></>
          }
          </div>
        {this.state.sourcesTree.map((item, index)=>{
            return(
              <ShowcaseItem
                key={index}
                item = {item}
                update={(s)=>{this.props.update(s)}}
                selected={(this.state.selectedSources.indexOf(item)!==-1)?true:false}
                changeCheckBox={(chkd)=>{
                  if (chkd){
                    this.setState({selectedSources: [...this.state.selectedSources, item]})
                  }else{
                    let newSelectedSources = []
                    this.state.selectedSources.map((itm,inx)=>{
                      if (itm.idSource!==item.idSource){
                        newSelectedSources = [...newSelectedSources, itm]
                      }
                    })
                    this.setState({selectedSources: newSelectedSources})
                  }
                }}
                style={{
                  height: this.context.selectedTable? 40: 200,
                  width: this.context.selectedTable? 340 : 160,
                  marginTop: 8,
                  cursor: 'pointer'
                }}
              />
            )
          })
        }
      </>:
        <h1 style={{margin: 40}}>{"Для начала следует выбрать нужный холодильник из перечня в шапке"}</h1>
      
      }
    </Container>
  );}
}

const Container = styled.div`
  display: flex;
  background-color: #E6E6E6;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100vw;
${'' /*   height: 100vh; */}
`;

const Rect = styled.div`
  display: flex;
  background-color: #E6E6E6;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  height: 100vh;
  width: 100vw;
`;
Showcase.contextType = Context
export default Showcase;
