import React, { Component, useState } from "react";
import styled, { css } from "styled-components";
import axi from '../functions/axiosf';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Context} from "../functions/context"

function Top(props) {

 
  const classes = useStyles();
  const [fridgesBase, addFridgesBase] = useState(props.fridges)
  let fridgesObj = {}
  fridgesBase.map((item, index)=>{
    fridgesObj[item.idf]=item
  })
    return (
      <Context.Consumer>
      {({selectedFridge, onChangeFridge}) => (
      <div
        className='top'
        style={{ 
          position: "fixed",
          backgroundColor: "#efefefd6",
          top: 0,
          zIndex: 5,
          }}
      >
        <FormControl
          variant='outlined' 
             className={classes.margin}>
          <InputLabel id="demo-simple-select-outlined-label">Адрес</InputLabel>
          <Select
            label="Адрес"
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedFridge.idf}
            onChange={
              (e)=>{
                onChangeFridge(fridgesObj[e.target.value])
                if(props.onChangeFridge){
                  props.onChangeFridge(fridgesObj[e.target.value])
                }
                }
              }

          >
            {fridgesBase.map((item, index)=>{
              return(
                <MenuItem key={index} value={item.idf}>{item.addres}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
      )}
      </Context.Consumer>
    );
  
}

export default Top;

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));