import React, { Component } from "react";
import styled, { css } from "styled-components";
import axi from '../functions/axiosf';

import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      visibilityLogInBlackOut: 'hidden',
      hoverColor: '',
      selectedColor: 'NewGoods',

    }
  }

  componentDidMount() {
    //console.log(this.props)
  }
 

  render(){
    let w = this.state.open ? 0 : 180
  return (
    <div>
      <div className="burgerButton"
      onClick={()=>{
        this.setState({open: !this.state.open})
        this.props.menuOpenStatusToggle(w)
        }}
      >
        <div className="centerized" data-open={this.state.open+''}>
          <div className="bar1"> </div>
          <div className="bar2"> </div>
          <div className="bar3"> </div>
        </div>
      </div>
    <Container
      className= 'headerContainer'
      style={{
          position: "fixed",
          zIndex: 200,
          width: this.props.menuOpenStatus,
        }}>
      <HeadButtonHome 
        className='HeadButton'
          onClick={() => { this.props.toggleTable()}}
        >
        <IoniconsIcon
          name={this.props.selectedTable?"ios-list":"ios-grid"}
          style={{
            color: "#eeee",
            fontSize: 30
          }}
        />
      </HeadButtonHome>
      <HeadButtonHome 
        className='HeadButton'
          onClick={() => { 
            this.props.route('Statistic')
            this.setState({selectedColor: 'Statistic'})
            }}
        >
          <MenuHomeTxt style={this.state.selectedColor=='Statistic'?{ color: '#d4e'}:{}}> Статистика</MenuHomeTxt>
        
      </HeadButtonHome>
      <HeadButtonHome
        className='HeadButton'
          onClick={() => { 
            this.props.route('SalesStat')
            this.setState({selectedColor: 'SalesStat'})
            }}
        >
          <MenuHomeTxt style={this.state.selectedColor=='SalesStat'?{ color: '#d4e'}:{}}>Продажи в холодильниках</MenuHomeTxt>
      </HeadButtonHome>
      <HeadButtonHome 
        className='HeadButton'
          onClick={() => { 
            this.props.route('CustomersLog')
            this.setState({selectedColor: 'CustomersLog'})
            }}
        >
          <MenuHomeTxt style={this.state.selectedColor=='CustomersLog'?{ color: '#d4e'}:{}}> Клиентский лог</MenuHomeTxt> 
      </HeadButtonHome> 
      <HeadButtonHome
        className='HeadButton'
          onClick={() => { 
            this.props.route('PingLog')
            this.setState({selectedColor: 'PingLog'})
            }}
        >
          <MenuHomeTxt style={this.state.selectedColor=='PingLog'?{ color: '#d4e'}:{}}>Температура в холодильниках</MenuHomeTxt>
       
      </HeadButtonHome>
      <HeadButtonHome
        className='HeadButton'
          onClick={() => { 
            this.props.route('Showcase')
            this.setState({selectedColor: 'Showcase'})
            }}
        >
          <MenuHomeTxt style={this.state.selectedColor=='Showcase'?{ color: '#d4e'}:{}}>Витрины</MenuHomeTxt>
       
      </HeadButtonHome>
      <HeadButtonHome
        className='HeadButton'
          onClick={() => { 
            this.props.route('NewGoods')
            this.setState({selectedColor: 'NewGoods'})
            }}
        >
          <MenuHomeTxt style={this.state.selectedColor=='NewGoods'?{ color: '#d4e'}:{}}>Выпуск продукции</MenuHomeTxt>
       
      </HeadButtonHome>
      <HeadButtonHome
        className='HeadButton'
          onClick={() => { 
            this.props.route('QuickAccessKit')
            this.setState({selectedColor: 'QuickAccessKit'})
            }}
        >
          <MenuHomeTxt style={this.state.selectedColor=='QuickAccessKit'?{ color: '#d4e'}:{}}>Заготовки меню холодильников</MenuHomeTxt>
       
      </HeadButtonHome>
      <HeadButtonHome
        className='HeadButton'
          onClick={() => { 
            this.props.route('NewMenuItem')
            this.setState({selectedColor: 'NewMenuItem'})
            }}
        >    
          <MenuHomeTxt style={this.state.selectedColor=='NewMenuItem'?{ color: '#d4e'}:{}}>Новый товар</MenuHomeTxt>  
      </HeadButtonHome>
      <HeadButtonHome
        className='HeadButton'
          onClick={() => { 
            this.props.route('Fridges')
            this.setState({selectedColor: 'Fridges'})
            }}
        >
          <MenuHomeTxt style={this.state.selectedColor=='Fridges'?{ color: '#d4e'}:{}}>Новый холодильник</MenuHomeTxt>
        
      </HeadButtonHome>
    </Container>
    </div>
  );
}
}
const Container = styled.div``;


const HeadButtonHome = styled.div``;

const MenuHomeTxt = styled.span`
  font-family: roboto-700;
  height: auto;
  flex: 1 1 0%;
  //color: #d4e4d4;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  transition: 1s;
`;


export default Header;
