import React from "react";
import styled, { css } from "styled-components";
import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";
import axi from "../functions/axiosf"
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ImageUploader from "./ImageUploader";




class NewGood extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      val: '',
      price: '',
      expiration: '',
      type: '',
      weight: '',
      protein: '',
      fats: '',
      carb: '',
      kcal: '',
      images: [],
      idp: 0
    }
  }

  componentDidMount(){
    this.setState(this.props.selectedItem)
    if (this.props.selectedItem.base64imgs) {
      this.setState({images: [{data_url: this.props.selectedItem.base64imgs}]})
    }
  }

  setImages = (i) => {this.setState({images: i})}

  onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    this.setImages(imageList);
  };

  create = () => {

    let params = {
      ...this.state,
      token: this.props.token,
    }
    let method = this.props.selectedIndex?'update':'create'
    axi("setProductName.php", method, params).then((result) => {
      if (result.type == 'approved') {
        if (method==='update'){
          alert('Номенклатурная еденица '+this.state.name+' успешно отредактирована')
        }
        if (method==='create'){
          alert('Новая номенклатурная еденица '+this.state.name+' успешно добавлена в базу')
        }
       
        this.props.update('NewMenuItem')
      } else {
        //this.setState({ screen: 'LogIn' })
        console.log(result)
      }
    }, (e) => { console.log(e) })

  }

  render(){
  return (
    <Container style={this.props.style}>
      <div className='margin16'>
        <TextField
            className='margin16'
            onChange={(e)=>this.setState({name: e.target.value})}
            value={this.state.name}
            //type="number"
            id="outlined-helperText"
            label={'название гового товара'}
            helperText={''}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">{this.state.name?"":""}</InputAdornment>,
            }}
          />
      </div>  
      <div className='margin16'> 
        <TextField
            className='margin16'
            onChange={(e)=>this.setState({val: e.target.value})}
            value={this.state.val}
            //type="number"
            id="outlined-helperText"
            label={'состав товара'}
            helperText={'отображается только на этикетке'}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">{this.state.val?"":""}</InputAdornment>,
            }}
          />
      </div>
      <div className='horizontalFields'>
        <div className='margin4100'>
          <TextField
              onChange={(e)=>this.setState({price: e.target.value})}
              value={this.state.price}
              type="number"
              id="outlined-helperText"
              label={'цена'}
              helperText={''}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">{this.state.price?"рублей":""}</InputAdornment>,
              }}
            />
        </div>
        <div className='margin4100'>
            <TextField
              onChange={(e)=>this.setState({expiration: e.target.value})}
              value={this.state.expiration}
              type="number"
              id="outlined-helperText"
              label={'срок годности'}
              helperText={''}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">{this.state.expiration?"часов":""}</InputAdornment>,
              }}
            />
        </div>
      </div>
<div className='horizontalFields'>
<div className='margin4100'>
      <FormControl variant="outlined" className={"margin4100"}>
        <InputLabel id="demo-simple-select-outlined-label">категория</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          onChange={(e)=>this.setState({type: e.target.value})}
          value={this.state.type}
          label="категория"
        >
          <MenuItem value="">
            <em>без категории</em>
          </MenuItem>
          <MenuItem value={'breakfast'}>завтрак</MenuItem>
          <MenuItem value={'soup'}>суп</MenuItem>
          <MenuItem value={'entree'}>второе</MenuItem>
          <MenuItem value={'salad'}>салат</MenuItem>
          <MenuItem value={'dessert'}>десерт</MenuItem>
          <MenuItem value={'drink'}>напиток</MenuItem>
          <MenuItem value={'bakery'}>выпечка</MenuItem>
          
          <MenuItem value={'x'}>Удалённый</MenuItem>
        </Select>
      </FormControl>
    </div>  
    <div className='margin4100'>
        <div className='margin4100'>
          <TextField
           
            onChange={(e)=>this.setState({weight: e.target.value})}
            value={this.state.weight}
            type="number"
            id="outlined-helperText"
            label={'вес'}
            helperText={''}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">{this.state.weight?"грамм":""}</InputAdornment>,
            }}
          />
        </div>
    </div>  </div>  
      <div className='horizontalFields'> 
        <div className='margin4100'> 
        <TextField
            className={'margin4100'}
            onChange={(e)=>this.setState({protein: e.target.value})}
            value={this.state.protein}
            type="number"
            id="outlined-helperText"
            label={'белки'}
            helperText={''}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">{this.state.protein?"грамм":""}</InputAdornment>,
            }}
          />
</div> 
<div className='margin4100'>
          <TextField
            className={'margin4100'}
            onChange={(e)=>this.setState({fats: e.target.value})}
            value={this.state.fats}
            type="number"
            id="outlined-helperText"
            label={'жиры'}
            helperText={''}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">{this.state.fats?"грамм":""}</InputAdornment>,
            }}
          />
        </div>  
      </div> 
      <div className='horizontalFields'>
      <div className='margin4100'>
          <TextField
            className={'margin4100'}
            onChange={(e)=>this.setState({carb: e.target.value})}
            value={this.state.carb}
            type="number"
            id="outlined-helperText"
            label={'углеводы'}
            helperText={''}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">{this.state.carb?"грамм":""}</InputAdornment>,
            }}
          />
</div>
          <div className='margin4100'>
          <TextField
            className={'margin4100'}
            onChange={(e)=>this.setState({kcal: e.target.value})}
            value={this.state.kcal}
            type="number"
            id="outlined-helperText"
            label={'калории'}
            helperText={''}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">{this.state.kcal?"к-калорий":""}</InputAdornment>,
            }}
          />
          </div>
</div>
<div className='margin16'>
          <ImageUploader
                  value={this.state.images}
                  onChange={(imageList, addUpdateIndex)=>this.onChange(imageList, addUpdateIndex)}
                />
</div>


{/*       <TextInput placeholder="Введите новое название  товара"
      
        onChange={(e) => { this.setState({name: e.target.value}) }}
        value={this.state.name}/>
      <TextInput placeholder="Состав товара (отображается только на этикетке)"
        onChange={(e) => { this.setState({ val: e.target.value }) }}
        value={this.state.val}
      />
      <TextInput placeholder="цена в рублях"
        type="number"
        onChange={(e) => { this.setState({ price: e.target.value }) }}
        value={this.state.val}
      />
      <TextInput placeholder="срок годности в часах"
        type="number"
        onChange={(e) => { this.setState({ exp: e.target.value }) }}
        value={this.state.val}
      />
      <TextInput placeholder="Тип"
        onChange={(e) => { this.setState({ type: e.target.value }) }}
        value={this.state.val}
      /> */}
      {this.state.name!=""&&   
        <IoniconsIcon
          name={this.props.selectedIndex?"ios-checkmark-circle":"ios-add-circle"}
          style={{
            color: "#426f4e",
            fontSize: 60
          }}
          onPress={() => this.create()}
        ></IoniconsIcon>
      }
    </Container>
  );}
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  
  align-items: center;
`;

const TextInput = styled.input`
  font-family: Roboto-300;
  color: #121212;
  height: 35px;
  width: 265px;
  border: none;
  margin-left: 8px; 
  fontSize: 26;
  background: transparent;
  background-color: rgba(203,203,203,1);
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 2px 2px 4px 1px #0a190375;
  padding-left: 16px;
`;

export default NewGood;
