import React, { Component } from "react";
import styled, { css } from "styled-components";
import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";
import axi from "../functions/axiosf"

class NewFridge extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addres: '',
      location_x: '',
      location_y: '',
      ip: '',
    }
  }

  create = () => {

    let params = {
      ...this.state,
      token: this.props.token,
    }
    axi("createNewFridge.php", null, params).then((result) => {
      if (result.type == 'approved') {
        alert('Новый холодильник по адресу ' + this.state.addres+' успешно добавлен в базу')
        this.setState({
          location_x: '',
          location_y: '',
          ip: '',
          addres: '' })
        this.props.update('Fridges')
      } else {
        //this.setState({ screen: 'LogIn' })
        console.log(result)
      }
    }, (e) => { console.log(e) })

  }

  render(){
  return (
    <Container style={this.props.style}>
      <b>Новый холодильник</b>
      <p>Адрес холодильника</p>
      <TextInput 
        placeholder="Большая Пушкарская 29"
        onChange={(e) => { this.setState({ addres: e.target.value}) }}
        value={this.state.addres}/>
      <p>Долгота</p>
      <TextInput 
        placeholder="30.297063"
        onChange={(e) => { this.setState({ location_y: e.target.value }) }}
        value={this.state.location_y}
      />
      <p>Широта</p>
      <TextInput
        placeholder="60.056123"
        onChange={(e) => { this.setState({ location_x: e.target.value }) }}
        value={this.state.location_x}
      />
      <p>Внешний IP адрес</p>
      <TextInput
        placeholder="60.160.0.1"
        onChange={(e) => { this.setState({ ip: e.target.value }) }}
        value={this.state.ip}
      />
      {this.state.addres!=""&&   
        this.state.location_x != "" &&
        this.state.location_y != "" &&
        this.state.ip != "" &&   
        <IoniconsIcon
          name="ios-add-circle"
          style={{
            color: "#399B6C",
            fontSize: 50
          }}
          onPress={() => this.create()}
        ></IoniconsIcon>
      }
    </Container>
  );}
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #b2d5ec;
  box-shadow: 2px 2px 8px 4px #96b3c8;
  border-radius: 20px;
`;

const TextInput = styled.input`
  font-family: Roboto-300;
  color: #121212;
  height: 35px;
  width: 265px;
  border: none;
  margin-left: 8px; 
  fontSize: 26;
  background: transparent;
  background-color: #D4E8F6;
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 2px 2px 4px 1px #96b3c8;
  padding-left: 16px;
  margin-bottom: 4px;

`;

export default NewFridge;
