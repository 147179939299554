import React, { Component } from "react";
import styled, { css, ThemeProvider } from "styled-components";
import SingleStatusBlock from "../components/SingleStatusBlock";
import CustomerLogInfo from "../components/CustomerLogInfo";
import Top from "../components/Top"
import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";
import TopTableLog from "../components/TopTableLog"
import DateTimePicker from 'react-datetime-picker';
import {ScatterChart, AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, Label, Legend, Bar, BarChart} from 'recharts'
import {lay} from '../constants/Layout'
import moment from 'moment';
import 'moment/locale/ru';
import {Context} from "../functions/context"

class SalesStat  extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        token: '',
        dateFrom: 0,
        dateTo: 0,
        onMouseDay: '',
        onMouseProduct: '',
        sourcesInFridge: [],
        sourcesTree: {},
        idf: '',
        logTree: {},
        sourcesQRs: {},
        qrs: [],
        log: [],
        stDates: [],
        stTimes: [],
        stDatesOdj: {},
        showcaseNow: {},
        soldOut: {},
      }
    }

    componentDidMount(){
      let t = +new Date()-60*60*24*1000*7
      this.setState({dateFrom: new Date(t), dateTo: new Date() })
      this._filterSources()
    }
    
    _filterSources = (idfr=this.context.selectedFridge.idf)=> {
      console.log(idfr)
      let st = []
      let stObj = {}
      let stDates = []
      let stTimes = []
      let stDatesOdj = {}
      let allNames = []
      let logTree = {}
      let log = []

      let qrs = []
      let sourcesQRs = {0:{idp:0}} 

      this.context.base.sources.map((item, index)=>{
        if (item.idf === idfr){         //&&item.stage!=="moveOut"
          if (qrs.indexOf(item.qr) == -1) {
              qrs = [...qrs, item.qr]
              sourcesQRs[item.qr] = []
            }
          let correctedTime = (item.timeCreate)*1000
          let dateString =  moment(Math.floor(correctedTime)).format('ll')
          if (stDates.indexOf(dateString) == -1) {
              stDates = [...stDates, dateString]
              stTimes = [ item.timeCreate, ...stTimes ]
              stDatesOdj[dateString] = {}
            }
          if (!stDatesOdj[dateString][this.context.baseTree[item.idp].name]){
            stDatesOdj[dateString][this.context.baseTree[item.idp].name]=1
          }else{
            stDatesOdj[dateString][this.context.baseTree[item.idp].name]=stDatesOdj[dateString][this.context.baseTree[item.idp].name]+1
          }
          sourcesQRs[item.qr] = item
          st = [...st, item]
          if (allNames.indexOf(item.idp) == -1){
            allNames = [item.idp, ...allNames]
            stObj[this.context.baseTree[item.idp].name]={}
            stObj[this.context.baseTree[item.idp].name].in_sale=[]
            stObj[this.context.baseTree[item.idp].name].moveOut=[]
            stObj[this.context.baseTree[item.idp].name].eaten=[]
            logTree[this.context.baseTree[item.idp].name]=[]
          }
          stObj[this.context.baseTree[item.idp].name][item.stage] = [...stObj[this.context.baseTree[item.idp].name][item.stage], item]
        }
      })
      //console.log(sourcesQRs)
      this.context.base.log.map((item, index)=>{
        if (item.idf===idfr&&
            item.operation==="BOUGHT"&&
            item.qrSource!=='0'&&item.qrSource!=='')
        {
          let thisIdp = sourcesQRs[item.qrSource].idp
          let thisName = this.context.baseTree[thisIdp].name
          logTree[thisName]=[...logTree[thisName], item]
          log = [...log, item]
        }
      })
/* 
      let reversDates = stDates.reverse()
      let soldOut = {}
      allNames.map((itemName, indexName)=>{
        let iName = this.context.baseTree[itemName].name
        if (stObj[iName].in_sale.length===0){
          stDates.map((item, index)=>{
            if(soldOut[iName]===undefined||soldOut[iName]===null){
              if(stDatesOdj[item][iName]!==undefined){
                soldOut[iName]=item
              }
            }
          })
        }
      })
 */

      this.setState({
        logTree: logTree ,
        sourcesTree: stObj, 
        sourcesInFridge: st, 
        idf: idfr,
        qrs: qrs,
        sourcesQRs: sourcesQRs,
        log: log,
        stDates: stDates,
        stTimes: stTimes,
        stDatesOdj: stDatesOdj,
        //soldOut: soldOut,
      })
    }

    handleDateChangeFrom = (d) => {
      this.setState({dateFrom: d})
      }

    handleDateChangeTo = (d) => {
      this.setState({dateTo: d})
      }

    getRandomColor() {
      let letters = '23456789AB';
      let color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 10)];
      }
      return color;
    }


    render(){

      let qrs = this.state.qrs
      let sourcesTree = this.state.sourcesQRs
      let sumsAllObj = {}
      let baseTree = {}
      this.props.base.productBase.map((item, index) => {
        baseTree[item.idp] = item
      })

      let prevTime = 0
      
      const rate = 24*60*60*1000
      let salesStat =[]
      this.state.log.map((item, index) => {
        if (+item.time>(Math.floor(+new Date(this.state.dateFrom)/1000))
            &&+item.time<=(Math.floor(+new Date(this.state.dateTo)/1000))
            &&item.operation==='BOUGHT'                   //двойная проверка, можно удалить
            &&item.idf===this.props.selectedFridge.idf)   //двойная проверка, можно удалить
          {
              let itemStat =  baseTree[sourcesTree[item.qrSource].idp]
              let statItemObj = {
                uDate: moment(Math.floor(item.time*1000)).format('ll'),
                time: item.time,
                item: itemStat,
                itemName: itemStat.name
              }
              if(sumsAllObj[itemStat.name]===undefined){
                sumsAllObj[itemStat.name]=1
              }else{
                sumsAllObj[itemStat.name] = sumsAllObj[itemStat.name]+1
              }
              salesStat=[statItemObj, ...salesStat]
          }
      })

      //console.log(salesStat)

      let toCharts = []
      let toChartsObj = {}
      let legend = []
      let uDates = []//
      salesStat.map((item, index) => {
        if (legend.indexOf(item.itemName) == -1){
          legend=[...legend, item.itemName]
        }
      })
      salesStat.map((item, index) => {
        if (uDates.indexOf(item.uDate) == -1){

          this.state.stTimes.map((itemStTimes, indexStTimes)=>
            {
              if(+itemStTimes>(+new Date(this.state.dateFrom)/1000)
                &&+itemStTimes<=(+new Date(this.state.dateTo)/1000)
                &&+itemStTimes>+prevTime
                &&+itemStTimes<+item.time){
                  let uDateProduction = moment(Math.floor(itemStTimes*1000)).format('ll')
                  if (uDates.indexOf(uDateProduction) == -1){
                    uDates=[...uDates, uDateProduction]
                    toChartsObj[uDateProduction] = {date: uDateProduction}
                  }
                }
            }
          )

          if (uDates.indexOf(item.uDate) == -1){
            uDates=[...uDates, item.uDate]
            toChartsObj[item.uDate] = {date: item.uDate,}
          }
          
          legend.map((it,ind)=>{
            toChartsObj[item.uDate][it]=null
          })
          
          toChartsObj[item.uDate][item.itemName]=1 
          prevTime = item.time
          //console.log(prevTime+'  '+item.uDate+'  '+item.time)
        }else{
          toChartsObj[item.uDate][item.itemName]=toChartsObj[item.uDate][item.itemName]+1
/* 
          if(toChartsObj[item.uDate][item.itemName]!==null){
            toChartsObj[item.uDate][item.itemName]=toChartsObj[item.uDate][item.itemName]+1
          }else{
            toChartsObj[item.uDate][item.itemName]=1
          }
           */
        }
      })
      let sumDelivery = {}
      let redZone = {}
      uDates.map((it,ind)=>{
          toCharts = [ ...toCharts, toChartsObj[it]]
        })
      
       
        let names = []
        if (toCharts.length>0){
          names = Object.keys(toCharts[0])
        }
      names.map((item, index)=>{
        if(index>0){
        sumDelivery[item]=0
          uDates.map((it,ind)=>{
            if(this.state.stDatesOdj[it]!==undefined){
              if (this.state.stDatesOdj[it][item]!==undefined){
                sumDelivery[item] = sumDelivery[item] + this.state.stDatesOdj[it][item]
              }
            }
          })

        }
      })

      console.log(uDates)
      let reversDates = uDates.reverse()
      let soldOut = {}
      names.map((itemName, indexName)=>{
        if (this.state.sourcesTree[itemName]!==undefined){
          if (this.state.sourcesTree[itemName].in_sale.length===0){
            reversDates.map((item, index)=>{
              if(soldOut[itemName]===undefined||soldOut[itemName]===null){
                if(toChartsObj[item]!==undefined){
                  if(toChartsObj[item][itemName]!==null&&toChartsObj[item][itemName]!==undefined){
                    soldOut[itemName]=item
                  }
                }
              }
            })
          }
        }
      })

  return (
    <Rect>
      <Top
        fridges={this.props.base.fridgeBase}
        onChangeFridge={(f)=>{
          this.props.onChangeFridge(f)
          this._filterSources(f.idf)
          }}

        selectedFridge={this.props.selectedFridge}
      />
      <div        
        className='top'
       
      />
      {this.context.selectedTable?
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 120,
              overflowY: 'scroll',
              maxWidth: lay.window.width-16,
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute"
              }}>
              {
                names.map((item, index)=>
                  <div key={index}
                  style={{
                    width: 150,
                    height: 50,
                    margin: 2,
                    overflow: 'hidden',
                    fontSize: 12,
                    backgroundColor: (index>0&&(sumsAllObj[item]/(this.state.sourcesTree[item].moveOut.length+this.state.sourcesTree[item].in_sale.length))<0.5)?'#e26c6c':'#e6e6e6'
                    
                  }}>
                    
                      <div
                      style={{
                        backgroundColor:(this.state.onMouseProduct===item)?'#4443':'transparent',
                        width: '100%',
                        height: '100%',
                        display: "flex",
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        textAlign: 'right',
                        paddingRight: 8
                      }}
                      >
                        <span 
                          style={{
                            paddingRight: 8
                          }}>
                            {item}
                          </span>
                      </div>
                  </div>
                )
              }
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}>

              {//подкладка под названия
                names.map((item, index)=>
                  <div key={index}
                  style={{
                    width: 150,
                    height: 50,
                    margin: 2,
                    overflow: 'hidden',
                  }}>
                  </div>
                )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}>

              {((moment(Math.floor(+new Date(this.state.dateTo))).format('ll'))===(moment(Math.floor(+new Date())).format('ll')))&&
                <>
                {names.map((item, index)=>
                  <div key={index}
                  style={{
                    width: 50,
                    height: 50,
                    margin: 2,
                    overflow: 'hidden',
                    backgroundColor: (index>0&&(sumsAllObj[item]/(this.state.sourcesTree[item].moveOut.length+this.state.sourcesTree[item].in_sale.length))<0.5)?'#e26c6c':'transparent'
                    
                    //fontSize: 12
                  }}>
                    
                      <div
                      style={{
                        backgroundColor:(this.state.onMouseProduct===item)?'#4443':'transparent',
                        width: '100%',
                        height: '100%',
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'right',
                        flexDirection: 'column',
                        fontSize: 10,
                      }}
                      >
                        {(index===0)?
                          <span
                            style={{
                              fontSize: 12
                            }}>начало остатки</span>
                          : 
                          <>
                            <span>{(this.state.sourcesTree[item].moveOut.length+this.state.sourcesTree[item].in_sale.length+sumsAllObj[item]-sumDelivery[item])+' всего'}</span>
                          </>
                        }
                      </div>
                  </div>
                )}
              
              <div 
                  style={{
                    width: 50,
                    height: 50,
                    margin: 2,
                    overflow: 'hidden',
                  }}>
                      <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'right',
                        flexDirection: 'column',
                        fontSize: 10,
                      }}
                      >
                          <span
                            style={{
                              fontSize: 12
                            }}>начало остатки</span>
                      </div>
                  </div>
              
              </>
            }
            </div>
            {toCharts.map((itemDate, indexDate)=>
              <div
              key={'d'+indexDate}
              style={{
                display: "flex",
                flexDirection: "column",
              }}>
                {names.map((itemProd, indexProd)=>{
                  if (indexProd===0){
                    return(
                    <div key={itemDate.date+'.'+indexProd}
                      style={{
                      width: 50,
                      height: 50,
                      margin: 2,
                      fontSize: 12,
                    }}>

                      <div
                      style={{
                        backgroundColor:(this.state.onMouseDay===itemDate.date)?'#4444':'transparent',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      > 
                        <span>{itemDate[itemProd].substring(0, itemDate[itemProd].length - 8)}</span>
                      </div>
                    </div>
                    )
                  }else{
                    return(
                    <div key={itemDate.date+'.'+indexProd}
                      style={{
                      width: 50,
                      height: 50,
                      margin: 2,
                    backgroundColor: soldOut[itemProd]===itemDate.date?'#4ef54e':((indexProd>0&&(sumsAllObj[itemProd]/(this.state.sourcesTree[itemProd].moveOut.length+this.state.sourcesTree[itemProd].in_sale.length))<0.5)?'#e26c6c':'transparent')
                    
                    }}>
                      <div
                        style={{
                          backgroundColor:(this.state.onMouseDay===itemDate.date||this.state.onMouseProduct===itemProd)?'#4444':'transparent',
                          width: '100%',
                          height: '100%',
                          display: "flex",
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onMouseEnter ={()=>{this.setState({onMouseDay:itemDate.date, onMouseProduct: itemProd})}}
                      >
                        {(this.state.stDatesOdj[itemDate.date]&&this.state.stDatesOdj[itemDate.date][itemProd])&&
                          <span style={{
                            fontSize: 12
                            }}>
                              {'+'+this.state.stDatesOdj[itemDate.date][itemProd]}
                            </span>
                        }
                        <span>{itemDate[itemProd]!==null?itemDate[itemProd]:'0'}</span>
                      </div>
                    </div>
                    )
                  }
                })}
                <div 
                      style={{
                      width: 50,
                      height: 50,
                      margin: 2,
                      fontSize: 12,
                    }}>

                      <div
                      style={{
                        backgroundColor:(this.state.onMouseDay===itemDate.date)?'#4444':'transparent',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      > 
                        <span>{itemDate.date.substring(0, itemDate.date.length - 8)}</span>
                      </div>
                    </div>
              </div>
            )
            }
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}>
            {((moment(Math.floor(+new Date(this.state.dateTo))).format('ll'))===(moment(Math.floor(+new Date())).format('ll')))&&
               <> 
                {names.map((item, index)=>
                  <div key={index}
                  style={{
                    width: 70,
                    height: 50,
                    margin: 2,
                    overflow: 'hidden',
                    backgroundColor: (index>0&&(sumsAllObj[item]/(this.state.sourcesTree[item].moveOut.length+this.state.sourcesTree[item].in_sale.length))<0.5)?'#e26c6c':'transparent'
                    
                  }}>
                    
                      <div
                      style={{
                        backgroundColor:(this.state.onMouseProduct===item)?'#4444':'transparent',
                        width: '100%',
                        height: '100%',
                        display: "flex",
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'right',
                        fontSize: 10
                      }}
                      >
                        {(index===0)?
                          <span
                            style={{
                              fontSize: 12
                              }}>конец остатки</span>
                          :
                          <>
                            <span>{this.state.sourcesTree[item].in_sale.length+' на точке'}</span>
                            <span>{this.state.sourcesTree[item].moveOut.length+' списано'}</span>
                            <span>{(this.state.sourcesTree[item].moveOut.length+this.state.sourcesTree[item].in_sale.length)+' всего'}</span>
                          </>
                          
                        }
                      </div>
                  </div>
                )}
                <div 
                  style={{
                    width: 50,
                    height: 50,
                    margin: 2,
                    overflow: 'hidden',
                  }}>
                      <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'right',
                        flexDirection: 'column',
                        fontSize: 10,
                      }}
                      >
                          <span
                            style={{
                              fontSize: 12
                            }}>конец остатки</span>
                      </div>
                  </div>
               </>
              }
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}>
            {
                names.map((item, index)=>
                  <div key={index}
                  style={{
                    width: 50,
                    height: 50,
                    margin: 2,
                    overflow: 'hidden',
                    backgroundColor: (index>0&&(sumsAllObj[item]/(this.state.sourcesTree[item].moveOut.length+this.state.sourcesTree[item].in_sale.length))<0.5)?'#e26c6c':'transparent'
                    //fontSize: 12
                  }}>
                    
                      <div
                      style={{
                        backgroundColor:(this.state.onMouseProduct===item)?'#4444':'transparent',
                        width: '100%',
                        height: '100%',
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'right'
                      }}
                      >
                        {(index===0)?
                          <span
                            style={{
                            fontSize: 12
                            }}>всего продано</span>
                          :
                          <span>{sumsAllObj[item]&&sumsAllObj[item]}</span>
                        }
                      </div>
                  </div>
                )
              }
              <div 
                  style={{
                    width: 50,
                    height: 50,
                    margin: 2,
                    overflow: 'hidden',
                  }}>
                      <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'right',
                        flexDirection: 'column',
                        fontSize: 10,
                      }}
                      >
                          <span
                            style={{
                              fontSize: 12
                            }}>всего продано</span>
                      </div>
                  </div>
            </div>
          </div>
        </>
        :
        <BarChart  width={lay.window.width} height={lay.window.height-220} data={toCharts}
          margin={{ top: 0, right: 30, left: 0, bottom: 0 }}>
          <XAxis 
            dataKey="date" />
          <YAxis/>
          <CartesianGrid strokeDasharray="3 2" />

          <Tooltip 
            filterNull={true}
            wrapperStyle={{ width: 300, height: 400 }}
          />
          {!lay.isSmallDevice&&
              <Legend />
          } 
          {legend.map((item, index)=> {
            let lineColor = this.getRandomColor()
            return(
              <Bar background={{ fill: lineColor+"10" }} connectNulls key={index} type="monotone" stroke={lineColor} fill={lineColor} dataKey={item} fillOpacity={0.3}/>
            )}
          )}
        </BarChart>
      }
        {/* тут рендерим график dataForRender по горизонтали временная шкала, по вертикали температура */}



        <Calendar>
          <Group2>
            <Date1Txt>Начало периода</Date1Txt>
            <DateTimePicker 
                onChange={(e) => { this.handleDateChangeFrom(e) }}
                value={this.state.dateFrom}
                locale={"ru-RU"}
                className='DateTimePicker'
                calendarIcon={ 
                  <IoniconsIcon
                    name="md-calendar"
                    style={{
                      color: "rgba(65,117,5,1)",
                      fontSize: 30,
                    }}
                  />}
            />
          </Group2>
          <Group2>
            <Date1Txt>Конец периода</Date1Txt>
              <DateTimePicker 
                onChange={(e) => { this.handleDateChangeTo(e) }}
                value={this.state.dateTo}
                locale={"ru-RU"}
                className='DateTimePicker'
                calendarIcon={<IoniconsIcon
                  name="md-calendar"
                  style={{
                    color: "rgba(117,5,5,1)",
                    fontSize: 30
                  }}
                />}
              />

          </Group2>
        </Calendar>
    </Rect>
  )
}}

SalesStat.contextType = Context

const Rect = styled.div`
  display: flex;
  background-color: #E6E6E6;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  minHeight: 100vh;
  width: 100vw;
`;

const List = styled.div`
  display: flex;
  background-color: #999;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  height: calc (100vh - 80px);
  width: 100vw;
  overflow-y: scroll;
`;



const Calendar = styled.div`
  background-color: #efefefd6;
  height: 120px;
  width: 320px;
  position: fixed;
  bottom: 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;    
  display: flex;
  flex-direction: column;
  justify-content: space-around;

`;

const Group2 = styled.div`
  width: 300px;
  height: 44px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

const Date1Txt = styled.span`
  font-family: Roboto-300;
  color: #121212;
  font-size: 16px;
  width: 90px;
  text-align: right;
  margin-right: 8px;
`;

const GoodsName = styled.span`
  font-family: Roboto-700;
  color: #121212;
  width: 140px;
  font-size: 14px;
  height: 34px;
  text-align: center;
`;


const LoremIpsum = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 14px;
  width: 35px;
  text-align: right;
  margin-right: 10px;
`;

export default SalesStat;
