import React, { Component } from "react";
import styled, { css } from "styled-components";
import SingleStatusBlock from "../components/SingleStatusBlock";
import CustomerLogInfo from "../components/CustomerLogInfo";
import Top from "../components/Top"
import IoniconsIcon from "react-native-vector-icons/dist/Ionicons";
import TopTableLog from "../components/TopTableLog"
import DateTimePicker from 'react-datetime-picker';

class CustomersLog  extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        token: '',
        dateFrom: 0,
        dateTo: 0,
        phoneFilter: '',
        addressFilter: '',
        productFilter: '',
      }
    }

    componentDidMount(){
      let t = +new Date()-60*60*24*1000*7
      this.setState({dateFrom: new Date(t), dateTo: new Date() })
    }
    
    handleDateChangeFrom = (d) => {
      this.setState({dateFrom: d})
      }

    handleDateChangeTo = (d) => {
      this.setState({dateTo: d})
      }

    render(){
      let listSum=0
      let qrs = []
      let sourcesTree = {0:{idp:0}} 

      this.props.base.sources.map((item, index) => {
       // if (item.timeCreate>(+new Date(this.state.dateFrom)/1000)&&item.timeCreate<=(+new Date(this.state.dateTo)/1000)){
            if (qrs.indexOf(item.qr) == -1) {
              qrs = [...qrs, item.qr]
              sourcesTree[item.qr] = []
            }
            sourcesTree[item.qr] = item
          
       // }
      })

      let baseTree = {}
      this.props.base.productBase.map((item, index) => {
        baseTree[item.idp] = item
      })

      let logFiltering = []
      
      this.props.base.log.map((item, index) => {
        let flagAddress = false
        let flagPhone  = false
        let flagProduct = false
        if (item.operation === 'BOUGHT'){
          if (item.time>(+new Date(this.state.dateFrom)/1000)&&item.time<=(+new Date(this.state.dateTo)/1000)){
            
            if(this.state.addressFilter!==''){
              if(this.state.addressFilter===this.props.base.fridgeAssoc[item.idf].addres){
                flagAddress = true
              }else{
                flagAddress= false
              }
            }else{
              flagAddress = true
            }

            if(this.state.phoneFilter!==''){
              if(this.state.phoneFilter===this.props.base.customers[item.idc].phone){
                flagPhone = true
              }else{
                flagPhone= false
              }
            }else{
              flagPhone = true
            }

            if(this.state.productFilter!==''){
              if(this.state.productFilter===baseTree[sourcesTree[item.qrSource].idp].name){
                flagProduct = true
              }else{
                flagProduct= false
              }
            }else{
              flagProduct = true
            }

            if(flagAddress&&flagPhone&&flagProduct){
              logFiltering =[...logFiltering, item]
              listSum=listSum+(+sourcesTree[item.qrSource].price)
            }
          }
        }})

  return (
    <Rect>
 
     <List>
      <div
        className='top'
        style={{
          flexDirection:'column',
          //width: this.props.selectedTable?720:'100%',
          //overflowX: this.props.selectedTable?true:false,
        }}
      />
      {!this.props.selectedTable?
      <></>
      :<div
      style={{height: 60, width: '100%'}}></div>}
      {!this.props.selectedTable?
      <></>
      :
      <TopTableLog
        column1={'телефон'}
        column2={'адрес'}
        column3={'товар'}
        column4={'цена'}
        column5={'время'}
      />
      }
      <List
        style={{
          overflowX: this.props.selectedTable?'scroll':'unset',
          width: '100%',
          flexDirection: this.props.selectedTable?'column':'row',
          overflowY: 'hidden'
          //flexWrap:this.props.selectedTable?false:true,
          }}
      >
        <div style={{height: 50, width: 300, padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <b>{'Cумма по списку, ИТОГО '+listSum+'руб.'}</b>
        </div>
        {logFiltering.map((item, index) => {
          return (
                <CustomerLogInfo
                  key={index}
                  source={sourcesTree[item.qrSource]}
                  product={baseTree[sourcesTree[item.qrSource].idp]}
                  fridge={this.props.base.fridgeAssoc[item.idf]}
                  customer={this.props.base.customers[item.idc]}
                  item={item}
                  onClickPhone={(p)=>{
                    if(this.state.phoneFilter===''){
                      this.setState({phoneFilter: p})
                    }else{
                      this.setState({phoneFilter: ''})
                    }
                  }}
                  onClickAdddress={(p)=>{
                    if(this.state.addressFilter===''){
                      this.setState({addressFilter: p})
                    }else{
                      this.setState({addressFilter: ''})
                    }
                  }}
                  onClickProduct={(p)=>{
                    if(this.state.productFilter===''){
                      this.setState({productFilter: p})
                    }else{
                      this.setState({productFilter: ''})
                    }
                  }}
                  style={!this.props.selectedTable?
                  {
                    width: 160,
                    height: 200,
                    boxShadow: '2px 2px 8px 1px #0a190375',
                  }:
                  {
                    width: 720,
                    height: 40,
                    boxShadow: '1px 1px 1px 1px #607d8b70',
                    margin: 1,
                    borderRadius: 0,
                  }}
                  
                selectedTable={this.props.selectedTable}
                />
                
              )
        })}
        </List>
        <div
          className='top'
        />
        <div
          className='top'
        />
      </List>
        <Calendar>
          <Group2>
            <Date1Txt>Начало периода</Date1Txt>
            <DateTimePicker 
                onChange={(e) => { this.handleDateChangeFrom(e) }}
                value={this.state.dateFrom}
                locale={"ru-RU"}
                className='DateTimePicker'
                calendarIcon={ 
                  <IoniconsIcon
                    name="md-calendar"
                    style={{
                      color: "rgba(65,117,5,1)",
                      fontSize: 30,
                    }}
                  />}
            />
          </Group2>
          <Group2>
            <Date1Txt>Конец периода</Date1Txt>
              <DateTimePicker 
                onChange={(e) => { this.handleDateChangeTo(e) }}
                value={this.state.dateTo}
                locale={"ru-RU"}
                className='DateTimePicker'
                calendarIcon={<IoniconsIcon
                  name="md-calendar"
                  style={{
                    color: "rgba(117,5,5,1)",
                    fontSize: 30
                  }}
                />}
              />

          </Group2>
        </Calendar>
    </Rect>
  )
}}

const Rect = styled.div`
  display: flex;
  background-color: #E6E6E6;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  height: 100vh;
  width: 100vw;
`;

const List = styled.div`
  display: flex;
  background-color: #999;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  height: calc (100vh - 80px);
  width: 100vw;
  overflow-y: scroll;
`;



const Calendar = styled.div`
  background-color: #efefefd6;
  height: 120px;
  width: 320px;
  position: fixed;
  bottom: 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;    
  display: flex;
  flex-direction: column;
  justify-content: space-around;

`;

const Group2 = styled.div`
  width: 300px;
  height: 44px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

const Date1Txt = styled.span`
  font-family: Roboto-300;
  color: #121212;
  font-size: 16px;
  width: 90px;
  text-align: right;
  margin-right: 8px;
`;

const GoodsName = styled.span`
  font-family: Roboto-700;
  color: #121212;
  width: 140px;
  font-size: 14px;
  height: 34px;
  text-align: center;
`;


const LoremIpsum = styled.span`
  font-family: Roboto-300;
  color: #121212;
  height: 14px;
  width: 35px;
  text-align: right;
  margin-right: 10px;
`;

export default CustomersLog;
