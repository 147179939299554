import React, { Component } from "react";
import styled, { css } from "styled-components";
import axi from '../functions/axiosf';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';

export default function TopSelectMenuItem(props) {

 
  const classes = useStyles();
  let group = ''

  let selectItems = []
          props.menuItems.map((item, index)=>{
            if (group!==item.type){
              group=item.type
              let rus = ''
              switch(item.type) {
                case 'breakfast':  
                  rus = 'завтрак'
                  break

                case 'soup':  
                  rus = 'суп'
                  break

                case 'entree':  
                  rus = 'второе'
                  break

                case 'salad':  
                  rus = 'салат'
                  break

                case 'dessert':  
                  rus = 'десерт'
                  break

                case 'drink':  
                  rus = 'напиток'
                  break

                case 'bakery':  
                  rus = 'выпечка'
                  break

                 case 'x':  
                  rus = 'удалённые'
                  break

                default:
                  
                  break
              }
              selectItems=[...selectItems, {type: item.type, selectGroup: rus}, item]
            }else{
              selectItems=[...selectItems, item]
            }
          })

    return (
      <div
        className='top'
        style={{ 
          position: "fixed",
          backgroundColor: "#efefefd6",
          zIndex: 5,
          }}
      >
        <FormControl className={classes.margin}>
        <InputLabel id="demo-customized-select-label">Товар для редактирования</InputLabel>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={props.selectedItem.name}
          onChange={(e)=>{props.onChangeItem(e.target.value)}}
          input={<BootstrapInput value={props.menuItems.name} />}
          style={{width: 300}}
        >
          <MenuItem key={0} value={{name: '',idp: 0}} >Новый товар</MenuItem>
          {selectItems.map((item, index)=>{
            if (item.selectGroup){
              return(
                <ListSubheader style={{backgroundColor:'#fff'}}>{item.selectGroup}</ListSubheader>
              )
            }else{
              return(
                <MenuItem style={{width: 300, height: "auto"}} key={item.id} value={item} >{item.name}</MenuItem>
              )
            }
          })}
        </Select>
      </FormControl>
      </div>
    );
  
}


const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));