import React, { Component } from "react";
import styled, { css } from "styled-components";
import CreeteQrStack from "../components/CreeteQrStack";
import CreateQrQuickStack from "../components/CreateQrQuickStack"
import SavedStack from "../components/SavedStack";
import Top from "../components/Top"


// экран с выпуском новой продукции
class NewGoods extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '',
    }
  }

  render(){
    
    let dates = []
    let sourcesTree = {}

    this.props.base.sources.map((item, index)=>{
      if (item.idf === this.props.selectedFridge.idf){
        if (dates.indexOf(item.timeCreate) == -1){
          dates = [item.timeCreate, ...dates]
          sourcesTree[item.timeCreate]=[]
        }
        sourcesTree[item.timeCreate] = [...sourcesTree[item.timeCreate], item]
      }
    
    })
    let kitGroups = []
    let kits = {}
    this.props.base.quickAccessKit.map((item, index)=>{
      if (kitGroups.indexOf(item.kitGroup) === -1){
        kitGroups = [...kitGroups, item.kitGroup]
        kits[item.kitGroup] = []
      }
      kits[item.kitGroup] = [...kits[item.kitGroup], item]
    })

    //console.log(sourcesTree)

    let t=+new Date()
  return (
    <Container
    style={{
          flexDirection: this.props.selectedTable?'column':'row',
          alignItems: this.props.selectedTable?'center':'flex-start',
        }}
     >
      <Top
        fridges={this.props.base.fridgeBase}
        onChangeFridge={(f)=>{this.props.onChangeFridge(f)}}
        selectedFridge={this.props.selectedFridge}
      />
      <div        
        className='top'
       
      />
      {(this.props.selectedFridge.idf!='')?
        <CreeteQrStack
          style={{
            height: 200,
            width: 320,
            marginTop: 8
          }}
          productNamesList={this.props.base.productBase}
          update={(s)=>{this.props.update(s)}}
          idf={this.props.selectedFridge.idf}
          mode={'CreateProductsQRs'}
          parent={'NewGoods'}
        />
        :
        <h1 style={{margin: 40}}>{"Для начала следует выбрать нужный холодильник из перечня в шапке"}</h1>
      }
      {(this.props.selectedFridge.idf!='')&&
      <>
        {kitGroups.map((item, index)=>{
          return(
            <CreateQrQuickStack
            key={index}
            style={{
                height: 200,
                width: 220,
                marginTop: 8
              }}
              token={this.props.token}
              productNamesList={this.props.base.productBase}
              kits={kits[item]}
              kitGroupName={item}
              update={(s)=>{this.props.update(s)}}
              idf={this.props.selectedFridge.idf}
              mode={'CreateKitProductsQRs'}
              parent={'NewGoods'}
            />)})
        }
        {dates.map((item, index)=>{
            return(
              <SavedStack
                key={index}
                sources={sourcesTree[item]}
                selectedTable={this.props.selectedTable}
                style={{
                  height: this.props.selectedTable? 40: 200,
                  width: this.props.selectedTable? 340 : 160,
                  marginTop: 8,
                  cursor: 'pointer'
                }}
                timeCreate={item}
                onClick={()=>{this.props.toPrint(sourcesTree[item])}}
              />
            )
          })
        }
      </>
      }
      {/* 
      <SavedStack
        sources={this.props.base.sources}
        style={{
          height: 200,
          width: 160,
          marginTop: 8
        }}
      ></SavedStack> 
      */}
    </Container>
  );}
}

const Container = styled.div`
  display: flex;
  background-color: #E6E6E6;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100vw;
${'' /*   height: 100vh; */}
`;

const Rect = styled.div`
  display: flex;
  background-color: #E6E6E6;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  height: 100vh;
  width: 100vw;
`;

export default NewGoods;
